
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import Editor from '@tinymce/tinymce-vue';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import storeSistema from '@/store/storeSistema';
import { ERelacaoArquivo } from '@/models/Enumeradores/MeuSistema/ERelacaoArquivo';

export default defineComponent({
  name: 'EditorHtml',
  props: {
    value: {
      type: String,
      default: '',
    },
    minHeight: {
      type: String,
      default: '100',
    },
    maxHeight: {
      type: String,
      default: '500',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Editor,
  },
  emits: ['update:value', 'change', 'blur'],
  setup(props, { emit }) {
    const servicoSistema = new ServicoSistema();
    const alturaMinima = ref(`${props.minHeight}px`);
    const alturaMaxima = ref(`${props.maxHeight}px`);
    const state = reactive({
      computedValue: computed({
        get: () => props.value,
        set: (val: string) => {
          emit('update:value', val);

          if (val !== props.value) {
            emit('change', val);
          }
        },
      }),
    });

    async function imageUpload(blobInfo:any) {
      const retornoArquivo = await servicoSistema.enviarArquivoUnicoPublico(storeSistema.getters.codigoEmpresaOperacao(), ERelacaoArquivo.Sistema, new File([blobInfo.blob()], blobInfo.filename()));
      return retornoArquivo.linkArquivo;
    }

    function blur() {
      emit('blur');
    }

    return {
      props,
      alturaMinima,
      alturaMaxima,
      state,
      blur,
      imageUpload,
    };
  },
});
