
import { computed, defineComponent } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarDepartamento from '@/components/Cadastros/Pessoas/Colaboradores/SelecionarDepartamento.vue';
import SelecionarFuncao from '@/components/Cadastros/Pessoas/Colaboradores/SelecionarFuncao.vue';
import SelecionarUsuario from '@/components/MeuSistema/Usuarios/SelecionarUsuario.vue';
import { IDTOColaboradorDepartamentoFuncao } from '@/models/DTO/Cadastros/Pessoas/IDTOColaboradorDepartamentoFuncao';

export default defineComponent({
  name: 'DadosColaborador',
  props: {
    codigoUsuario: {
      type: Number,
    },
    departamentoFuncao: {
      type: Array as () => IDTOColaboradorDepartamentoFuncao[],
      required: true,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    Card,
    SelecionarUsuario,
    SelecionarEmpresa,
    SelecionarDepartamento,
    SelecionarFuncao,
  },
  emits: ['update:codigoUsuario', 'update:departamentoFuncao'],
  setup(props, { emit }) {
    const computedDepartamentoFuncao = computed({
      get: () => props.departamentoFuncao,
      set: (valor: IDTOColaboradorDepartamentoFuncao[]) => {
        emit('update:departamentoFuncao', valor);
      },
    });

    const computedCodigoUsuario = computed({
      get: () => (props.codigoUsuario ? props.codigoUsuario : 0),
      set: (valor: number) => {
        emit('update:codigoUsuario', valor);
      },
    });

    return {
      props,
      computedCodigoUsuario,
      computedDepartamentoFuncao,
    };
  },
});
