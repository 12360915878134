
import { defineComponent, computed, reactive } from 'vue';
import draggable from 'vuedraggable';
import { ICardKanban, IColunaKanban } from '@/core/models/IKanban';
import CardKanban from './CardKanban.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'ColunaKanban',
  components: {
    draggable,
    CardKanban,
  },
  emits: ['update:coluna', 'selecaoCardAlterada', 'colunaCardAlterada', 'identificadoresSelecionados', 'qtdSelecaoAlterada', 'ordemCardsAlterada', 'clickNovo', 'clickVisualizar', 'clickSubMenu'],
  props: {
    coluna: {
      type: Object as () => IColunaKanban,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      heightCorpo: 0,
    });

    const computedColuna = computed({
      get: () => props.coluna,
      set: (coluna: IColunaKanban) => {
        emit('update:coluna', coluna);
      },
    });

    function obterHeightCorpo() {
      if (props.coluna.textoTotalizador !== '' && props.coluna.textoTotalizador !== undefined) {
        if (props.coluna.botaoNovo) {
          return 81;
        }
        return 44;
      } if (props.coluna.botaoNovo) {
        return 61;
      }
      return 24;
    }

    const computedValorTotalizador = computed(() => {
      let valorTotal = 0;
      if (UtilitarioGeral.validaLista(computedColuna.value.cards)) {
        computedColuna.value.cards.forEach((card) => {
          valorTotal += card.valor;
        });
      }
      return valorTotal;
    });

    function atualizarQtdSelecionados(identificadorCard: string) {
      computedColuna.value.qtdSelecionados = 0;
      computedColuna.value.identificadoresSelecionados = [];
      computedColuna.value.cards.forEach((c) => {
        if (c.selecionado) {
          computedColuna.value.qtdSelecionados += 1;
          computedColuna.value.identificadoresSelecionados.push(c.identificador);
        }
      });
      computedColuna.value.itensSelecionados = (computedColuna.value.qtdSelecionados > 0);
      emit('qtdSelecaoAlterada', identificadorCard);
      emit('identificadoresSelecionados', computedColuna.value.identificadoresSelecionados);
    }

    function selecaoCardAlterada(identificadorCard: string) {
      emit('selecaoCardAlterada', identificadorCard);
    }

    function colunaCardAlterada(card:ICardKanban, identificadorNovaColuna: string) {
      emit('colunaCardAlterada', card, identificadorNovaColuna);
    }

    function clickNovo() {
      emit('clickNovo', props.coluna.identificador);
    }

    function clickVisualizar(identificadorCard: string) {
      emit('clickVisualizar', identificadorCard);
    }

    function clickSubMenu(identificadorCard: string) {
      emit('clickSubMenu', identificadorCard);
    }

    function ordemCardsAlterada(identificadorColuna: string) {
      emit('ordemCardsAlterada', identificadorColuna);
    }

    function atualizarItensSelecionados() {
      computedColuna.value.qtdSelecionados = 0;
      computedColuna.value.identificadoresSelecionados = [];
      for (let index = 0; index < computedColuna.value.cards.length; index += 1) {
        computedColuna.value.cards[index].selecionado = computedColuna.value.itensSelecionados;
        if (computedColuna.value.itensSelecionados) {
          computedColuna.value.qtdSelecionados += 1;
          computedColuna.value.identificadoresSelecionados.push(computedColuna.value.cards[index].identificador);
        }
      }
      emit('identificadoresSelecionados', computedColuna.value.identificadoresSelecionados);
    }

    return {
      computedColuna,
      computedValorTotalizador,
      state,
      props,
      UtilitarioGeral,
      UtilitarioMascara,
      atualizarItensSelecionados,
      atualizarQtdSelecionados,
      selecaoCardAlterada,
      colunaCardAlterada,
      clickNovo,
      clickVisualizar,
      ordemCardsAlterada,
      obterHeightCorpo,
      clickSubMenu,
    };
  },
});

