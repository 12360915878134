
import {
  defineComponent, reactive, computed, onMounted,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import DisplayCampoPersonalizado from './DisplayCampoPersonalizado.vue';
import { ICampoPersonalizado, ILinhaCamposPersonalizados } from '@/models/Entidades/MeuSistema/PersonalizacoesTelas/IPersonalizacaoTela';
import ServicoPersonalizacaoTelas from '@/servicos/MeuSistema/ServicoPersonalizacaoTela';
import storeSistema from '@/store/storeSistema';

export default defineComponent({
  name: 'DisplayLinhaCamposPersonalizados',
  props: {
    codigoRegistro: {
      type: Number,
      required: true,
    },
    linha: {
      type: Object as () => ILinhaCamposPersonalizados,
      required: true,
    },
    campos: {
      type: Object as () => ICampoPersonalizado[],
      required: true,
    },
  },
  components: {
    DisplayCampoPersonalizado,
  },
  emits: ['update:linha', 'selecionarCampo', 'editarCampo', 'removerCampo'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const servicoPersonalizacaoTelas = new ServicoPersonalizacaoTelas();
    servicoPersonalizacaoTelas.requisicaoSistema();

    const state = reactive({
      carregando: false,
    });

    const computedLinha = computed({
      get: () => props.linha,
      set: (linha: ILinhaCamposPersonalizados) => {
        emit('update:linha', linha);
      },
    });

    onMounted(async () => {
      state.carregando = true;
      if (props.linha.codigo > 0) {
        const colunas = await servicoPersonalizacaoTelas.obterColunasCampos(props.linha.codigo, storeSistema.getters.codigoEmpresaOperacao());

        colunas.forEach((c, index) => {
          const indiceCampo = props.campos.findIndex((c) => c.codigo === colunas[index].codigoCampoPersonalizado);
          colunas[index].campoPersonalizado = props.campos[indiceCampo];
        });
        computedLinha.value.colunas = colunas;
      }
      state.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      computedLinha,
    };
  },
});
