
import {
  defineComponent, reactive, computed, onMounted,
} from 'vue';
import DisplayLinhaCamposPersonalizados from './DisplayLinhaCamposPersonalizados.vue';
import { IGrupoCamposPersonalizado, IValorCampoPersonalizado } from '@/models/Entidades/MeuSistema/PersonalizacoesTelas/IPersonalizacaoTela';
import ServicoPersonalizacaoTelas from '@/servicos/MeuSistema/ServicoPersonalizacaoTela';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoCampoPersonalizado } from '@/models/Enumeradores/MeuSistema/PersonalizacoesTelas/ETipoCampoPersonalizado';

export default defineComponent({
  name: 'DisplayGrupoCampos',
  props: {
    codigoRegistro: {
      type: Number,
      required: true,
    },
    grupo: {
      type: Object as () => IGrupoCamposPersonalizado,
      required: true,
    },
  },
  components: {
    DisplayLinhaCamposPersonalizados,
  },
  emits: ['update:grupo'],
  setup(props, { emit }) {
    const servicoPersonalizacaoTelas = new ServicoPersonalizacaoTelas();
    servicoPersonalizacaoTelas.requisicaoSistema();
    const state = reactive({
      carregando: false,
    });

    const computedGrupo = computed({
      get: () => props.grupo,
      set: (grupo: IGrupoCamposPersonalizado) => {
        emit('update:grupo', grupo);
      },
    });

    function inicializarValoresCampos() {
      computedGrupo.value.campos.forEach((campo) => {
        const indiceCampo = computedGrupo.value.campos.findIndex((c) => c.codigo === campo.codigo);
        computedGrupo.value.campos[indiceCampo].valores = [] as IValorCampoPersonalizado[];
        if (campo.tipoCampo !== ETipoCampoPersonalizado.ListaSelecao) {
          computedGrupo.value.campos[indiceCampo].valores[0] = {} as IValorCampoPersonalizado;
          computedGrupo.value.campos[indiceCampo].valores[0].codigoCampoPersonalizado = campo.codigo;
          computedGrupo.value.campos[indiceCampo].valores[0].registro = props.codigoRegistro;
          computedGrupo.value.campos[indiceCampo].valores[0].valor = '';
        }
      });
    }

    function preencherValoresPadrao() {
      computedGrupo.value.campos.forEach((campo) => {
        const indiceCampo = computedGrupo.value.campos.findIndex((c) => c.codigo === campo.codigo);
        if (UtilitarioGeral.valorValido(campo.valorPadrao)) {
          if (computedGrupo.value.campos[indiceCampo].tipoCampo === ETipoCampoPersonalizado.ListaSelecao) {
            computedGrupo.value.campos[indiceCampo].valores[0] = {} as IValorCampoPersonalizado;
            computedGrupo.value.campos[indiceCampo].valores[0].codigoCampoPersonalizado = campo.codigo;
            computedGrupo.value.campos[indiceCampo].valores[0].registro = props.codigoRegistro;
            computedGrupo.value.campos[indiceCampo].valores[0].valor = campo.valorPadrao;
          } else {
            computedGrupo.value.campos[indiceCampo].valores[0].valor = campo.valorPadrao;
          }
        }
      });
    }

    async function preencherValores() {
      inicializarValoresCampos();
      if (props.codigoRegistro > 0) {
        const valores = await servicoPersonalizacaoTelas.obterValoresCampos(props.codigoRegistro, props.grupo.codigo, storeSistema.getters.codigoEmpresaOperacao());
        if (UtilitarioGeral.validaLista(valores)) {
          valores.forEach((v) => {
            const indiceCampo = computedGrupo.value.campos.findIndex((c) => c.codigo === v.codigoCampoPersonalizado);
            if (computedGrupo.value.campos[indiceCampo].tipoCampo === ETipoCampoPersonalizado.ListaSelecao) {
              computedGrupo.value.campos[indiceCampo].valores.push(v);
            } else {
              computedGrupo.value.campos[indiceCampo].valores[0].valor = v.valor;
            }
          });
        } else {
          preencherValoresPadrao();
        }
      } else {
        preencherValoresPadrao();
      }
    }

    onMounted(async () => {
      state.carregando = true;
      if (props.grupo.codigo > 0) {
        computedGrupo.value.campos = await servicoPersonalizacaoTelas.obterCamposPersonalizados(props.grupo.codigo, storeSistema.getters.codigoEmpresaOperacao());
        computedGrupo.value.linhas = await servicoPersonalizacaoTelas.obterLinhasCampos(props.grupo.codigo, storeSistema.getters.codigoEmpresaOperacao());
        await preencherValores();
      }
      state.carregando = false;
    });

    return {
      props,
      state,
      computedGrupo,
    };
  },
});
