
import { defineComponent, reactive, watch } from 'vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import Icone from '@/core/components/Icone.vue';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import { IDTOPessoaEmail } from '@/models/DTO/Cadastros/Pessoas/IDTOPessoaEmail';
import { useTelaBase } from '@/core/composables/TelaBase';
import CampoCpfCnpj from '@/core/components/Tela/CampoCpfCnpj.vue';

export default defineComponent({
  name: 'EnviarEmailPessoaModal',
  components: {
    Icone,
    CampoCpfCnpj,
  },
  props: {
    visivel: {
      type: Boolean,
    },
    tituloModal: {
      type: String,
      default: 'Confirme para quais E-mails deseja enviar',
    },
    empresa: {
      type: Number,
      required: true,
    },
    codigoPessoa: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:visivel', 'enviarEmail'],
  setup(props, { emit }) {
    const { modalBase } = useModalBase(props, emit);

    const { apresentarMensagemAlerta } = useTelaBase();

    const servicoPessoa = new ServicoPessoa();

    const state = reactive({
      pessoaEmails: {} as IDTOPessoaEmail,
      emailsAdicionais: [] as string[],
      buscandoEmails: true,
    });

    async function enviarEmail() {
      const emails: string[] = [];
      if (UtilitarioGeral.valorValido(state.pessoaEmails.email)) {
        emails.push(state.pessoaEmails.email);
      }

      if (UtilitarioGeral.validaLista(state.pessoaEmails.contatoEmails)) {
        state.pessoaEmails.contatoEmails.forEach((contatoEmail) => {
          if (UtilitarioGeral.valorValido(contatoEmail.email)) {
            emails.push(contatoEmail.email);
          }
        });
      }
      let emailInvalido = false;
      if (UtilitarioGeral.validaLista(state.emailsAdicionais)) {
        state.emailsAdicionais.forEach((email) => {
          if (UtilitarioGeral.valorValido(email)) {
            if (email.includes('@')) {
              emails.push(email);
            } else {
              apresentarMensagemAlerta('Parece que você informou um e-mail inválido.');
              emailInvalido = true;
            }
          }
        });
      }
      if (emailInvalido) return;

      if (UtilitarioGeral.validaLista(emails)) {
        emit('enviarEmail', emails);
      } else {
        apresentarMensagemAlerta('Nenhum e-mail foi informado.');
      }
    }

    function adicionarEmail() {
      state.emailsAdicionais.push('');
    }

    function removerEmailContatoPessoa(index: number) {
      state.pessoaEmails.contatoEmails.splice(index, 1);
    }
    function removerEmailAdicional(index: number) {
      state.emailsAdicionais.splice(index, 1);
    }

    watch(async () => modalBase.computedVisivel, async () => {
      if (modalBase.computedVisivel) {
        state.buscandoEmails = true;
        state.pessoaEmails = {} as IDTOPessoaEmail;
        state.pessoaEmails.codigoPessoa = 0;
        state.pessoaEmails.contatoEmails = [];
        state.emailsAdicionais = [];

        if (props.codigoPessoa > 0) {
          const pessoaEmails = await servicoPessoa.obterEmailsEContatosEmail(props.codigoPessoa, props.empresa);
          state.buscandoEmails = false;
          if (UtilitarioGeral.objetoValido(pessoaEmails)) {
            if (UtilitarioGeral.validaCodigo(pessoaEmails.codigoPessoa)) {
              state.pessoaEmails = pessoaEmails;
            }
          }

          if (state.pessoaEmails.codigoPessoa === 0) {
            apresentarMensagemAlerta('Não foi possível obter os e-mails.');
            modalBase.computedVisivel = false;
          }
        }
      }
    });

    return {
      state,
      props,
      modalBase,
      enviarEmail,
      UtilitarioGeral,
      adicionarEmail,
      removerEmailContatoPessoa,
      removerEmailAdicional,
    };
  },
});
