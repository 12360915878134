import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8a3f37b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ss-div-conteudo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_card_kanban = _resolveComponent("card-kanban")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", {
    class: "ss-div-coluna ss-fadein",
    style: _normalizeStyle('border-color: ' + _ctx.computedColuna.cor)
  }, [
    _createElementVNode("div", {
      class: "ss-div-titulo",
      style: _normalizeStyle('background-color: ' + _ctx.computedColuna.cor)
    }, [
      _createVNode(_component_a_checkbox, {
        class: "ss-kanban-coluna-checkbox",
        checked: _ctx.computedColuna.itensSelecionados,
        "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedColuna.itensSelecionados) = $event)),
        onChange: _ctx.atualizarItensSelecionados
      }, null, 8, ["checked", "onChange"]),
      _createElementVNode("span", null, _toDisplayString(_ctx.computedColuna.titulo), 1)
    ], 4),
    (_ctx.computedColuna.botaoNovo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("button", {
            class: "ant-btn ant-btn-primary ss-botao-novo ss-sombra-suave",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clickNovo && _ctx.clickNovo(...args)))
          }, _toDisplayString(_ctx.computedColuna.descricaoBotaoNovo), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      style: _normalizeStyle('height: calc(100% - ' + _ctx.obterHeightCorpo() +'px);')
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_draggable, {
          class: "ss-drag-cards",
          list: _ctx.computedColuna.cards,
          group: "cards",
          itemKey: "identificador",
          handle: ".ss-mover",
          onSort: _cache[2] || (_cache[2] = ($event: any) => (_ctx.ordemCardsAlterada(_ctx.computedColuna.identificador)))
        }, {
          item: _withCtx(({ element, index }) => [
            (_openBlock(), _createBlock(_component_card_kanban, {
              key: element.identificador,
              cor: _ctx.computedColuna.cor,
              identificadorColuna: _ctx.computedColuna.identificador,
              card: _ctx.computedColuna.cards[index],
              "onUpdate:card": ($event: any) => ((_ctx.computedColuna.cards[index]) = $event),
              onSelecaoAlterada: _ctx.selecaoCardAlterada,
              onColunaAlterada: _ctx.colunaCardAlterada,
              onClickVisualizar: _ctx.clickVisualizar,
              onClickSelecionar: _ctx.atualizarQtdSelecionados,
              onClickSubMenu: _ctx.clickSubMenu
            }, {
              menus: _withCtx(() => [
                _renderSlot(_ctx.$slots, "menus", {}, undefined, true)
              ]),
              _: 2
            }, 1032, ["cor", "identificadorColuna", "card", "onUpdate:card", "onSelecaoAlterada", "onColunaAlterada", "onClickVisualizar", "onClickSelecionar", "onClickSubMenu"]))
          ]),
          _: 3
        }, 8, ["list"])
      ])
    ], 4),
    (_ctx.UtilitarioGeral.valorValido(_ctx.computedColuna.textoTotalizador))
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "ss-div-rodape",
          style: _normalizeStyle('font-size:13px;color: ' + _ctx.computedColuna.cor)
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.computedColuna.textoTotalizador) + ": " + _toDisplayString(_ctx.UtilitarioMascara.mascararValor(_ctx.computedValorTotalizador,2, true)), 1)
        ], 4))
      : _createCommentVNode("", true)
  ], 4))
}