import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IServico } from '@/models/Entidades/Cadastros/PrestacaoServicos/IServico';
import { IDTOServico } from '@/models/DTO/Cadastros/PrestacaoServicos/IDTOServico';

/**
 * Serviço de Cadastro de Serviços
 * Fornece todas regras de negócios e lógicas relacionado a Tipo de custos.
 */

class ServicoServico implements IServicoBase<IServico> {
    endPoint = 'servicos';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOServico): string {
      return objeto.descricao;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async incluir(objeto: IServico): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, objeto);
      return result.data;
    }

    public async alterar(objeto: IServico): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${objeto.codigo}`, objeto);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<IServico> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public apresentacaoLocalPrestacaoServico(localPrestacao: number): string {
      if (localPrestacao === 0) return 'No endereço do Tomador';
      if (localPrestacao === 1) return 'No endereço do Prestador';

      return '';
    }

    public apresentacaoIssRetido(issRetido: number): string {
      if (issRetido === 0) return 'ISS Retido';
      if (issRetido === 1) return 'Normal';
      if (issRetido === 2) return 'Substituição';
      return '';
    }
}
export default ServicoServico;
