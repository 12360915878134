import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, {
      class: "ss-tamanho-completo",
      value: _ctx.computedCodigoSelecionado,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedCodigoSelecionado) = $event)),
      placeholder: _ctx.props.placeholder,
      optionFilterProp: "label",
      options: _ctx.state.listaOpcoesTipoContatos,
      disabled: _ctx.props.disabled,
      onFocus: _ctx.obterListaTipos
    }, null, 8, ["value", "placeholder", "options", "disabled", "onFocus"])
  ]))
}