
import {
  defineComponent, reactive, onMounted, computed,
} from 'vue';
import { ICardKanban } from '@/core/models/IKanban';
import Icone from '../Icone.vue';

export default defineComponent({
  name: 'CardKanban',
  components: {
    Icone,
  },
  props: {
    card: {
      type: Object as () => ICardKanban,
      required: true,
    },
    identificadorColuna: {
      type: String,
      required: true,
    },
    cor: {
      type: String,
      required: true,
    },
  },
  emits: ['update:card', 'selecaoAlterada', 'colunaAlterada', 'clickVisualizar', 'clickSelecionar', 'clickSubMenu'],
  setup(props, { emit }) {
    const state = reactive({});

    const computedCard = computed({
      get: () => props.card,
      set: (val: ICardKanban) => {
        emit('update:card', val);
      },
    });

    onMounted(() => {
      if (props.identificadorColuna !== props.card.identificadorColuna) {
        emit('colunaAlterada', props.card, props.identificadorColuna);
      }
    });

    function selecaoCardAlterada() {
      emit('selecaoAlterada', props.card.identificador);
    }

    function clickVisualizar() {
      emit('clickVisualizar', props.card.identificador);
    }

    function clickSelecionar() {
      emit('clickSelecionar', props.card.identificador);
    }

    function clickSubMenu() {
      emit('clickSubMenu', props.card.identificador);
    }

    return {
      state,
      props,
      computedCard,
      selecaoCardAlterada,
      clickVisualizar,
      clickSelecionar,
      clickSubMenu,
    };
  },
});
