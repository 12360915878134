import axios, { AxiosInstance, AxiosPromise } from 'axios';
import storeSistema from '@/store/storeSistema';

const tokenIntegracao = '1AN6N94fxyUgFwrLvEDxWRiCjPWkBRAhT4';
const authHeader = () => ({
  TokenIntegracao: tokenIntegracao,
});

export default class ApiUtilitarios {
  protected instance: AxiosInstance;

  protected readonly baseURL: string;

  public constructor() {
    this.baseURL = storeSistema.state.configuracaoApp.urlApiUtilitario;
    this.instance = axios.create({
      baseURL: this.baseURL,
      timeout: 480000,
      headers: {
        TokenIntegracao: tokenIntegracao,
        'Content-Type': 'application/json',
      },
    });

    this.interceptaAxios();
  }

  public get(path?: string): AxiosPromise<unknown> {
    return this.instance({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    });
  }

  public post(path?: string, data?: unknown): AxiosPromise<unknown> {
    return this.instance({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader() },
    });
  }

  public interceptaAxios():void {
    /**
     * axios interceptors runs before and after a request, letting the developer modify req,req more
     * For more details on axios interceptor see https://github.com/axios/axios#interceptors
     */
    this.instance.interceptors.request.use((config) => {
      // do something before executing the request
      // For example tag along the bearer access token to request header or set a cookie
      const requestConfig = config;
      requestConfig.headers.TokenIntegracao = `${tokenIntegracao}`;
      return requestConfig;
    });

    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
        const { response } = error;
        const originalRequest = error.config;
        if (response) {
          if (response.status === 401) {
            window.location.href = 'https://vocenaoestaautenticado.com.br';
          } else if (response.status === 403) {
            window.location.href = 'https://sempermissao.com.br';
          } else if (response.status === 404) {
            window.location.href = 'https://registronaoencontrado.com.br';
          } else if (response.status === 500) {
            window.location.href = 'https://erro.com.br';
          } else {
            return originalRequest;
          }
        }
        return Promise.reject(error);
      },
    );
  }
}
