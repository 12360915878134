export enum EExigibilidadeIss
{
    Exigivel = 0,
    NaoIncidencia = 1,
    Isencao = 2,
    Exportacao = 3,
    Imunidade = 4,
    SuspensaDecisaoJudicial = 5,
    SuspensaProcessoAdministrativo = 6
}
