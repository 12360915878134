
import { computed, defineComponent, reactive } from 'vue';
import { IPessoaDadosBancarios } from '@/models/Entidades/Cadastros/Pessoas/IPessoaDadosBancarios';
import SelecionarBanco from '../Financeiro/SelecionarBanco.vue';

export default defineComponent({
  name: 'DadosBancariosPessoa',
  props: {
    dadosBancarios: {
      type: Object as ()=> IPessoaDadosBancarios,
      required: true,
    },
  },
  components: {
    SelecionarBanco,
  },
  emits: ['update:dadosBancarios'],
  setup(props, { emit }) {
    const state = reactive({

    });

    const computedDadosBancarios = computed({
      get: () => props.dadosBancarios,
      set: (valor: IPessoaDadosBancarios) => {
        emit('update:dadosBancarios', valor);
      },
    });

    return {
      computedDadosBancarios,
      state,
    };
  },
});
