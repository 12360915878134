import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_3 = { class: "ant-col ant-col-xs-18 ant-col-xl-4" }
const _hoisted_4 = { class: "ant-col ant-col-xs-6 ant-col-xl-2" }
const _hoisted_5 = { class: "ant-col ant-col-xs-18 ant-col-xl-4" }
const _hoisted_6 = { class: "ant-col ant-col-xs-6 ant-col-xl-2" }
const _hoisted_7 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selecionar_banco = _resolveComponent("selecionar-banco")!
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_selecionar_banco, {
          codigoSelecionado: _ctx.computedDadosBancarios.codigoBanco,
          "onUpdate:codigoSelecionado": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedDadosBancarios.codigoBanco) = $event))
        }, null, 8, ["codigoSelecionado"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_form_item, {
          label: "Agência",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              maxlength: "10",
              class: "ant-input",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedDadosBancarios.agencia) = $event))
            }, null, 512), [
              [_vModelText, _ctx.computedDadosBancarios.agencia]
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_a_form_item, {
          label: "Dígito",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              maxlength: "2",
              class: "ant-input",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedDadosBancarios.agenciaDv) = $event))
            }, null, 512), [
              [_vModelText, _ctx.computedDadosBancarios.agenciaDv]
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_a_form_item, {
          label: "Conta",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              maxlength: "10",
              class: "ant-input",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedDadosBancarios.conta) = $event))
            }, null, 512), [
              [_vModelText, _ctx.computedDadosBancarios.conta]
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_a_form_item, {
          label: "Dígito",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              maxlength: "2",
              class: "ant-input",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedDadosBancarios.contaDv) = $event))
            }, null, 512), [
              [_vModelText, _ctx.computedDadosBancarios.contaDv]
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_a_form_item, {
          label: "Chave Pix",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              maxlength: "32",
              class: "ant-input",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedDadosBancarios.chavePix) = $event))
            }, null, 512), [
              [_vModelText, _ctx.computedDadosBancarios.chavePix]
            ])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}