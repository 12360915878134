
import { computed, defineComponent, reactive } from 'vue';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IOption } from '@/core/models/AntDesign/IOption';
import { ETipoPessoa } from '@/models/Enumeradores/Compartilhados/ETipoPessoa';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import CampoTelefone from '@/core/components/Tela/CampoTelefone.vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoCpf from '@/core/components/Tela/CampoCpf.vue';
import CampoCnpj from '@/core/components/Tela/CampoCnpj.vue';
import ServicoUtilitario from '@/servicos/MeuSistema/ServicoUtilitario';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarTipoAtividade from '@/components/Compartilhados/SelecionarTipoAtividade.vue';

export default defineComponent({
  name: 'DadosPrincipaisPessoa',
  props: {
    codigo: {
      type: Number,
      default: 0,
    },
    tipoPessoa: {
      type: Number,
      default: 0,
    },
    cpf: {
      type: String,
      default: '',
    },
    nomeCompleto: {
      type: String,
      default: '',
    },
    nomeAbreviado: {
      type: String,
      default: '',
    },
    cnpj: {
      type: String,
      default: '',
    },
    razaoSocial: {
      type: String,
      default: '',
    },
    nomeFantasia: {
      type: String,
      default: '',
    },
    documentoIdentificacao: {
      type: String,
      default: '',
    },
    nomeCompletoEstrangeiro: {
      type: String,
      default: '',
    },
    nomeAbreviadoEstrangeiro: {
      type: String,
      default: '',
    },
    contato: {
      type: String,
      default: '',
    },
    telefone: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    site: {
      type: String,
      default: '',
    },
    tipoAtividade: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Icone,
    CampoTelefone,
    CampoCpf,
    CampoCnpj,
    SelecionarTipoAtividade,
  },
  emits: ['update:codigo', 'update:tipoPessoa',
    'update:cpf', 'update:nomeCompleto', 'update:nomeAbreviado',
    'update:documentoIdentificacao', 'update:nomeCompletoEstrangeiro', 'update:nomeAbreviadoEstrangeiro',
    'update:cnpj', 'update:razaoSocial', 'update:nomeFantasia',
    'update:contato', 'update:telefone', 'update:email', 'update:site', 'update:tipoAtividade', 'atualizarDadosConsultaCnpj',
    'blurCpfCnpj', 'blurNome'],
  setup(props, { emit }) {
    const { montaOpcoesComListaItemGenerico } = useTelaBase();

    const servicoPessoa = new ServicoPessoa();
    const servicoUtilitario = new ServicoUtilitario();

    const state = reactive({
      listaTipoPessoa: [] as IOption[],
      carregandoTipoPessoa: false,
      consultandoCnpj: false,
    });

    const computedCodigo = computed({
      get: () => props.codigo,
      set: (valor: number) => {
        emit('update:codigo', valor);
      },
    });

    const computedTipoPessoa = computed({
      get: () => props.tipoPessoa,
      set: (valor: number) => {
        emit('update:tipoPessoa', valor);
      },
    });

    const computedCpf = computed({
      get: () => props.cpf,
      set: (valor: string) => {
        emit('update:cpf', valor);
      },
    });

    const computedNomeCompleto = computed({
      get: () => props.nomeCompleto,
      set: (valor: string) => {
        emit('update:nomeCompleto', valor);
      },
    });

    const computedNomeAbreviado = computed({
      get: () => props.nomeAbreviado,
      set: (valor: string) => {
        emit('update:nomeAbreviado', valor);
      },
    });

    const computedDocumentoIdentificacao = computed({
      get: () => props.documentoIdentificacao,
      set: (valor: string) => {
        emit('update:documentoIdentificacao', valor);
      },
    });

    const computedNomeCompletoEstrangeiro = computed({
      get: () => props.nomeCompletoEstrangeiro,
      set: (valor: string) => {
        emit('update:nomeCompletoEstrangeiro', valor);
      },
    });

    const computedNomeAbreviadoEstrangeiro = computed({
      get: () => props.nomeAbreviadoEstrangeiro,
      set: (valor: string) => {
        emit('update:nomeAbreviadoEstrangeiro', valor);
      },
    });

    const computedCnpj = computed({
      get: () => props.cnpj,
      set: (valor: string) => {
        emit('update:cnpj', valor);
      },
    });

    const computedRazaoSocial = computed({
      get: () => props.razaoSocial,
      set: (valor: string) => {
        emit('update:razaoSocial', valor);
      },
    });

    const computedNomeFantasia = computed({
      get: () => props.nomeFantasia,
      set: (valor: string) => {
        emit('update:nomeFantasia', valor);
      },
    });

    const computedContato = computed({
      get: () => props.contato,
      set: (valor: string) => {
        emit('update:contato', valor);
      },
    });

    const computedTelefone = computed({
      get: () => props.telefone,
      set: (valor: string) => {
        emit('update:telefone', valor);
      },
    });

    const computedEmail = computed({
      get: () => props.email,
      set: (valor: string) => {
        emit('update:email', valor);
      },
    });

    const computedSite = computed({
      get: () => props.site,
      set: (valor: string) => {
        emit('update:site', valor);
      },
    });

    const computedTipoAtividade = computed({
      get: () => props.tipoAtividade,
      set: (valor: number) => {
        emit('update:tipoAtividade', valor);
      },
    });
    (async () => {
      state.carregandoTipoPessoa = true;
      const retornoListaTipoPessoa = await servicoPessoa.obterListaTipoPessoa();
      state.listaTipoPessoa = montaOpcoesComListaItemGenerico(retornoListaTipoPessoa);
      state.carregandoTipoPessoa = false;
    })();

    async function consultaCNPJ(cnpj:string) {
      if (state.consultandoCnpj) {
        return;
      }
      state.consultandoCnpj = true;
      const retornoConsultaCnpj = await servicoUtilitario.consultaCNPJ(UtilitarioMascara.removerMascara(cnpj));
      if (UtilitarioGeral.objetoValido(retornoConsultaCnpj)) {
        if (UtilitarioGeral.valorValido(retornoConsultaCnpj.razaoSocial)) {
          computedRazaoSocial.value = retornoConsultaCnpj.razaoSocial;
          computedNomeFantasia.value = retornoConsultaCnpj.nomeFantasia;
          computedEmail.value = retornoConsultaCnpj.contato.email;
          const telefone = UtilitarioMascara.removerMascara(retornoConsultaCnpj.contato.telefonePrincipal);
          computedTelefone.value = UtilitarioMascara.mascararTelefoneFixoOuCelular(telefone);
          if (UtilitarioGeral.validaLista(retornoConsultaCnpj.qsa)) {
            computedContato.value = retornoConsultaCnpj.qsa[0].nome;
          }
          emit('atualizarDadosConsultaCnpj', retornoConsultaCnpj);
        }
        emit('blurCpfCnpj');
      }
      state.consultandoCnpj = false;
    }

    function blurCpfCnpj() {
      emit('blurCpfCnpj');
    }

    function blurNome() {
      emit('blurNome');
    }

    return {
      computedCodigo,
      computedTipoPessoa,
      computedCpf,
      computedNomeCompleto,
      computedNomeAbreviado,
      computedDocumentoIdentificacao,
      computedNomeCompletoEstrangeiro,
      computedNomeAbreviadoEstrangeiro,
      computedCnpj,
      computedRazaoSocial,
      computedNomeFantasia,
      computedContato,
      computedTelefone,
      computedEmail,
      computedSite,
      state,
      ETipoPessoa,
      consultaCNPJ,
      blurCpfCnpj,
      blurNome,
      computedTipoAtividade,
    };
  },
});
