
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import { Modal } from 'ant-design-vue';
import ContatoPessoaModal from '@/components/Cadastros/Pessoas/ContatoPessoaModal.vue';
import Icone from '@/core/components/Icone.vue';
import { IPessoaContato } from '@/models/Entidades/Cadastros/Pessoas/IPessoaContato';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import { ETipoOperacao } from '@/core/models/Enumeradores/ETipoOperacao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import storeSistema from '@/store/storeSistema';
import ServicoTipoContato from '@/servicos/Cadastros/Pessoas/ServicoTipoContato';
import { ITipoContato } from '@/models/Entidades/Cadastros/Pessoas/Contatos/ITipoContato';
import { IContatoTelefone } from '@/models/Entidades/Cadastros/Pessoas/Contatos/IContatoTelefone';

export default defineComponent({
  name: 'ContatosPessoa',
  props: {
    contatos: {
      type: Array as () => IPessoaContato[],
      required: true,
    },
    relacoes: {
      type: Array as () => number[],
      required: true,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    Icone,
    ContatoPessoaModal,
  },
  emits: ['update:contatos'],
  setup(props, { emit }) {
    const refContatoPessoaModal = ref<InstanceType<typeof ContatoPessoaModal>>();
    const servicoTipoContato = new ServicoTipoContato();

    const state = reactive({
      exibirContatoModal: false,
      listaTiposContato: [] as ITipoContato[],
      colunasGrade: [] as IColumn[],
      windowWidth: (window.innerWidth - 100),
    });

    const computedContatosPessoa = computed({
      get: () => props.contatos,
      set: (valor: IPessoaContato[]) => {
        emit('update:contatos', valor);
      },
    });

    (async () => {
      state.listaTiposContato = await servicoTipoContato.obterTodos(storeSistema.getters.empresasUsuarioAutenticado());
    })();

    state.colunasGrade = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 0, visible: false, ellipsis: true,
      },
      {
        title: 'Ações', key: 'acoes', position: 1, visible: true, fixed: 'left', width: 70, align: 'center', ellipsis: true,
      },
      {
        title: 'Tipo', dataIndex: 'tipoContato', key: 'TipoContato', position: 2, visible: true, width: 90, ordering: false, align: 'left', ellipsis: true,
      },
      {
        title: 'Nome', dataIndex: 'nome', key: 'Nome', position: 3, visible: true, width: 150, ordering: false, align: 'left', ellipsis: true,
      },
      {
        title: 'E-mail', key: 'Email', position: 4, visible: true, ordering: false, align: 'left', width: 150, ellipsis: true,
      },
      {
        title: 'Telefones(s)', key: 'Telefones', position: 5, visible: true, ordering: false, align: 'left', width: 300, ellipsis: true,
      },
    ];

    async function salvarContato(tipoOperacao:ETipoOperacao, pessoaContato: IPessoaContato) {
      if (tipoOperacao === ETipoOperacao.Incluir) {
        computedContatosPessoa.value.push(pessoaContato);
      } else if (tipoOperacao === ETipoOperacao.Alterar) {
        const indexContato = computedContatosPessoa.value.findIndex((c) => c.codigo === pessoaContato.codigo);
        if (indexContato >= 0) {
          computedContatosPessoa.value[indexContato].codigo = pessoaContato.codigo;
          computedContatosPessoa.value[indexContato].codigoPessoa = pessoaContato.codigoPessoa;
          computedContatosPessoa.value[indexContato].codigoContato = pessoaContato.codigoContato;
          computedContatosPessoa.value[indexContato].contato.codigo = pessoaContato.contato.codigo;
          computedContatosPessoa.value[indexContato].contato.codigoTipoContato = pessoaContato.contato.codigoTipoContato;
          computedContatosPessoa.value[indexContato].contato.nome = pessoaContato.contato.nome;
          computedContatosPessoa.value[indexContato].contato.cargo = pessoaContato.contato.cargo;
          computedContatosPessoa.value[indexContato].contato.email = pessoaContato.contato.email;
          computedContatosPessoa.value[indexContato].contato.observacoes = pessoaContato.contato.observacoes;
          computedContatosPessoa.value[indexContato].contato.permissao.codigo = pessoaContato.contato.permissao.codigo;
          computedContatosPessoa.value[indexContato].contato.permissao.codigoContato = pessoaContato.contato.permissao.codigoContato;
          computedContatosPessoa.value[indexContato].contato.permissao.consultarXml = pessoaContato.contato.permissao.consultarXml;
          computedContatosPessoa.value[indexContato].contato.permissao.cpfCnpjAutorizacao = pessoaContato.contato.permissao.cpfCnpjAutorizacao;
          computedContatosPessoa.value[indexContato].contato.permissao.receberNfe = pessoaContato.contato.permissao.receberNfe;
          computedContatosPessoa.value[indexContato].contato.permissao.receberNfse = pessoaContato.contato.permissao.receberNfse;
          computedContatosPessoa.value[indexContato].contato.permissao.receberCobranca = pessoaContato.contato.permissao.receberCobranca;

          computedContatosPessoa.value[indexContato].contato.telefones = [];
          pessoaContato.contato.telefones.forEach((telefone) => {
            computedContatosPessoa.value[indexContato].contato.telefones.push(telefone);
          });

          computedContatosPessoa.value[indexContato].contato.preferencias.codigo = pessoaContato.contato.preferencias.codigo;
          computedContatosPessoa.value[indexContato].contato.preferencias.codigoContato = pessoaContato.contato.preferencias.codigoContato;
          computedContatosPessoa.value[indexContato].contato.preferencias.tipoPreferencia = pessoaContato.contato.preferencias.tipoPreferencia;

          computedContatosPessoa.value[indexContato].contato.preferencias.segunda = pessoaContato.contato.preferencias.segunda;
          computedContatosPessoa.value[indexContato].contato.preferencias.terca = pessoaContato.contato.preferencias.terca;
          computedContatosPessoa.value[indexContato].contato.preferencias.quarta = pessoaContato.contato.preferencias.quarta;
          computedContatosPessoa.value[indexContato].contato.preferencias.quinta = pessoaContato.contato.preferencias.quinta;
          computedContatosPessoa.value[indexContato].contato.preferencias.sexta = pessoaContato.contato.preferencias.sexta;
          computedContatosPessoa.value[indexContato].contato.preferencias.sabado = pessoaContato.contato.preferencias.sabado;
          computedContatosPessoa.value[indexContato].contato.preferencias.domingo = pessoaContato.contato.preferencias.domingo;

          computedContatosPessoa.value[indexContato].contato.preferencias.manha = pessoaContato.contato.preferencias.manha;
          computedContatosPessoa.value[indexContato].contato.preferencias.tarde = pessoaContato.contato.preferencias.tarde;
          computedContatosPessoa.value[indexContato].contato.preferencias.noite = pessoaContato.contato.preferencias.noite;
        }
      }
    }

    function preencherTelefones(telefones: IContatoTelefone[]):string {
      let telefonesFormatados = '';
      if (UtilitarioGeral.validaLista(telefones)) {
        telefones.forEach((item) => {
          telefonesFormatados += UtilitarioGeral.valorValido(telefonesFormatados) ? ', ' : '';
          telefonesFormatados += UtilitarioMascara.mascararTelefoneFixoOuCelular(item.telefone);
        });
      }

      return telefonesFormatados;
    }

    function novoContato() {
      if (refContatoPessoaModal.value !== undefined) {
        refContatoPessoaModal.value.novoContato();
      }
    }
    function editarContato(pessoaContato: IPessoaContato) {
      if (refContatoPessoaModal.value !== undefined) {
        refContatoPessoaModal.value.editarContato(pessoaContato);
      }
    }

    function removerContato(codigoEndereco: number) {
      const index = computedContatosPessoa.value.findIndex((c) => c.codigo === codigoEndereco);
      if (index !== -1) {
        computedContatosPessoa.value.splice(index, 1);
      }
    }

    function confirmaExclusao(pessoaContato: IPessoaContato) {
      Modal.confirm({
        title: 'Você deseja remover o contato:',
        content: `${pessoaContato.contato.nome} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => { removerContato(pessoaContato.codigo); },
      });
    }

    function obterDescricaoTipoContato(tipoContato: number): string {
      if (UtilitarioGeral.validaLista(state.listaTiposContato)) {
        const tipoEncontrado = state.listaTiposContato.find((c) => c.codigo === tipoContato);
        if (tipoEncontrado !== undefined) {
          return tipoEncontrado.descricao;
        }
      }

      return '';
    }

    return {
      props,
      computedContatosPessoa,
      state,
      salvarContato,
      novoContato,
      editarContato,
      confirmaExclusao,
      obterDescricaoTipoContato,
      preencherTelefones,
      refContatoPessoaModal,
    };
  },
});
