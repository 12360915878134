
import { computed, defineComponent, reactive } from 'vue';
import { IOptionString } from '@/core/models/AntDesign/IOption';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';

export default defineComponent({
  name: 'SelecionarMarcador',
  props: {
    marcadoresSelecionados: {
      type: Array as () => string[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Digite para definir um marcador',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:marcadoresSelecionados'],
  setup(props, { emit }) {
    let debounce = 0;
    const servicoPessoa = new ServicoPessoa();

    const state = reactive({
      listaMarcadores: [] as IOptionString[],
    });

    const computedMarcadoresSelecionados = computed({
      get: () => props.marcadoresSelecionados,
      set: (valor: string[]) => {
        emit('update:marcadoresSelecionados', valor);
      },
    });

    async function pesquisarMarcador(valorPesquisa: any) {
      state.listaMarcadores = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const marcadoresApi = await servicoPessoa.consultarMarcadores(valorPesquisa);
        marcadoresApi.forEach((marcador) => {
          const optionMarcador:IOptionString = { value: marcador, label: marcador };
          state.listaMarcadores.push(optionMarcador);
        });
      }, 600);
    }

    return {
      props,
      state,
      pesquisarMarcador,
      computedMarcadoresSelecionados,
    };
  },
});
