
import {
  computed, defineComponent, reactive,
} from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { useTelaBase } from '@/core/composables/TelaBase';
import ServicoCidade from '@/servicos/Cadastros/Localizacoes/ServicoCidade';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'SelecionarCidade',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    nomeSelecionado: {
      type: String,
      default: '',
    },
    apresentarEstado: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:nomeSelecionado'],
  setup(props, { emit }) {
    const { montaOpcoesComListaConsultaRapida } = useTelaBase();

    let debounce = 0;
    const servicoCidade = new ServicoCidade();

    const state = reactive({
      listaCidades: [] as IOption[],
      fetching: false,
      emDigitacao: false,
    });

    async function obterCidadePorCodigo(valor: any) {
      if (state.emDigitacao) { return; }
      if (valor === undefined) { return; }
      if (valor === null) { return; }
      if (valor === 0) { return; }

      if (props.varios) {
        if (props.codigosSelecionados.length === 0) { return; }
      } else if (props.codigoSelecionado === 0) { return; }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.listaCidades)) {
        if (props.varios) {
          const listaCodigosConsulta = state.listaCidades.map((c) => c.value);
          const diferencas = props.codigosSelecionados.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const existeLista = state.listaCidades.find((c) => c.value === props.codigoSelecionado);
          if (existeLista !== undefined) {
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.listaCidades = [];

      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        apenasAtivos: false, recursoAssociado: '',
      };

      if (props.varios) {
        parametrosConsultaRapida.filtrarPorVariosCodigos = true;
        parametrosConsultaRapida.valores = valor;
      } else {
        parametrosConsultaRapida.filtrarPorCodigo = true;
        parametrosConsultaRapida.valor = valor;
      }

      const listaCidades = await servicoCidade.consultaRapida(parametrosConsultaRapida, props.apresentarEstado);
      state.listaCidades = montaOpcoesComListaConsultaRapida(listaCidades);
    }

    async function pesquisarCidade(valorPesquisa: any) {
      state.emDigitacao = true;

      if (!UtilitarioGeral.valorValido(valorPesquisa)) { return; }

      state.listaCidades = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida: IParametrosConsultaRapida = {
          valor: valorPesquisa, apenasAtivos: true, recursoAssociado: '', filtrarTextoExato: false,
        };

        const listaCidades = await servicoCidade.consultaRapida(parametrosConsultaRapida, props.apresentarEstado);
        state.listaCidades = montaOpcoesComListaConsultaRapida(listaCidades);
      }, 600);
    }

    function obterCidade():any {
      if (props.codigoSelecionado === 0) {
        return undefined;
      }
      obterCidadePorCodigo(props.codigoSelecionado);
      return props.codigoSelecionado;
    }

    const computedNomeSelecionado = computed({
      get: () => props.nomeSelecionado,
      set: (val: string) => {
        emit('update:nomeSelecionado', val);
      },
    });

    const computedCodigoSelecionado = computed({
      get: () => obterCidade(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
        const option = state.listaCidades.find((c) => c.value === val);
        if (option?.label !== undefined && option?.label !== '') {
          computedNomeSelecionado.value = option?.label;
        }
      },
    });

    function obterCidades():number[] {
      obterCidadePorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterCidades(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    return {
      props,
      state,
      pesquisarCidade,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      computedNomeSelecionado,
    };
  },
});
