
import { computed, defineComponent, reactive } from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { useTelaBase } from '@/core/composables/TelaBase';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';

export default defineComponent({
  name: 'SelecionarRelacaoPessoa',
  props: {
    relacaoPrincipal: {
      type: Number,
      default: 0,
    },
    varias: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number,
      default: 0,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:relacaoPrincipal', 'change'],
  setup(props, { emit }) {
    const { montaOpcoesComListaItemGenerico } = useTelaBase();

    const servicoPessoa = new ServicoPessoa();

    const state = reactive({
      listaRelacoesPessoaApi: [] as IOption[],
      listaRelacoesPessoa: [] as IOption[],
      carregando: false,
    });

    (async () => {
      state.carregando = true;
      const listaRelacoesPessoa = await servicoPessoa.obterListaTipoRelacaoPessoa();
      state.listaRelacoesPessoaApi = montaOpcoesComListaItemGenerico(listaRelacoesPessoa);
      state.listaRelacoesPessoa = state.listaRelacoesPessoaApi;

      if (props.relacaoPrincipal > 0) {
        const opcaoPrincipal = state.listaRelacoesPessoa.find((c) => c.value === props.relacaoPrincipal);
        if (opcaoPrincipal !== undefined) {
          opcaoPrincipal.disabled = true;
        }
      }
      state.carregando = false;
    })();

    const computedCodigoSelecionado = computed({
      get: () => props.codigoSelecionado,
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
      },
    });

    const computedCodigosSelecionados = computed({
      get: () => props.codigosSelecionados,
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varias ? props.codigosSelecionados : props.codigoSelecionado);
    }

    return {
      props,
      state,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      change,
    };
  },
});
