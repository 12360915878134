import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selecionar_usuario = _resolveComponent("selecionar-usuario")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_empresa = _resolveComponent("selecionar-empresa")!
  const _component_selecionar_departamento = _resolveComponent("selecionar-departamento")!
  const _component_selecionar_funcao = _resolveComponent("selecionar-funcao")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_card, { titulo: "DADOS DO COLABORADOR" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          label: "Usuário",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_selecionar_usuario, {
              codigoSelecionado: _ctx.computedCodigoUsuario,
              "onUpdate:codigoSelecionado": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedCodigoUsuario) = $event)),
              empresas: _ctx.props.empresas
            }, null, 8, ["codigoSelecionado", "empresas"])
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(_component_a_form, {
          layout: "vertical",
          name: "form-dados-colaborador-pessoa",
          model: _ctx.computedDepartamentoFuncao
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedDepartamentoFuncao, (departamentoFuncaoColaborador, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "ant-row",
                key: index,
                style: {"display":"flex","margin-bottom":"8px"}
              }, [
                _withDirectives(_createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_a_form_item, {
                    label: "Empresa",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_empresa, {
                        codigoSelecionado: departamentoFuncaoColaborador.codigoEmpresa,
                        "onUpdate:codigoSelecionado": ($event: any) => ((departamentoFuncaoColaborador.codigoEmpresa) = $event),
                        disabled: true
                      }, null, 8, ["codigoSelecionado", "onUpdate:codigoSelecionado"])
                    ]),
                    _: 2
                  }, 1024)
                ], 512), [
                  [_vShow, _ctx.computedDepartamentoFuncao.length > 1]
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.computedDepartamentoFuncao.length > 1?'ant-col ant-col-xs-24 ant-col-xl-6':'ant-col ant-col-xs-24 ant-col-xl-12')
                }, [
                  _createVNode(_component_a_form_item, {
                    label: "Departamento",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_departamento, {
                        codigoSelecionado: departamentoFuncaoColaborador.codigoDepartamento,
                        "onUpdate:codigoSelecionado": ($event: any) => ((departamentoFuncaoColaborador.codigoDepartamento) = $event),
                        empresas: [departamentoFuncaoColaborador.codigoEmpresa]
                      }, null, 8, ["codigoSelecionado", "onUpdate:codigoSelecionado", "empresas"])
                    ]),
                    _: 2
                  }, 1024)
                ], 2),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_a_form_item, {
                    label: "Função",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_funcao, {
                        codigoSelecionado: departamentoFuncaoColaborador.codigoFuncao,
                        "onUpdate:codigoSelecionado": ($event: any) => ((departamentoFuncaoColaborador.codigoFuncao) = $event),
                        empresas: [departamentoFuncaoColaborador.codigoEmpresa]
                      }, null, 8, ["codigoSelecionado", "onUpdate:codigoSelecionado", "empresas"])
                    ]),
                    _: 2
                  }, 1024)
                ])
              ]))
            }), 128))
          ]),
          _: 1
        }, 8, ["model"]), [
          [_vShow, _ctx.computedDepartamentoFuncao.length > 0]
        ])
      ]),
      _: 1
    })
  ]))
}