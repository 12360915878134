import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_display_linha_campos_personalizados = _resolveComponent("display-linha-campos-personalizados")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!

  return (_openBlock(), _createBlock(_component_a_skeleton, {
    active: "",
    loading: _ctx.state.carregando,
    paragraph: { rows: 2 }
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_component_a_form, {
        key: _ctx.props.grupo.codigo,
        layout: "vertical"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.grupo.linhas, (linha, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createVNode(_component_display_linha_campos_personalizados, {
                "codigo-registro": _ctx.props.codigoRegistro,
                linha: _ctx.props.grupo.linhas[index],
                "onUpdate:linha": ($event: any) => ((_ctx.props.grupo.linhas[index]) = $event),
                campos: _ctx.props.grupo.campos,
                "onUpdate:campos": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.props.grupo.campos) = $event))
              }, null, 8, ["codigo-registro", "linha", "onUpdate:linha", "campos"])
            ]))
          }), 128))
        ]),
        _: 1
      }))
    ]),
    _: 1
  }, 8, ["loading"]))
}