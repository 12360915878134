
import {
  computed, defineComponent, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import EnderecoPessoaModal from '@/components/Cadastros/Pessoas/EnderecoPessoaModal.vue';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IOption } from '@/core/models/AntDesign/IOption';
import Icone from '@/core/components/Icone.vue';
import { IPessoaEndereco } from '@/models/Entidades/Cadastros/Pessoas/IPessoaEndereco';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import { ETipoOperacao } from '@/core/models/Enumeradores/ETipoOperacao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import ApresentarCidade from '@/components/Cadastros/Localizacoes/Cidades/ApresentarCidade.vue';

export default defineComponent({
  name: 'EnderecosPessoa',
  props: {
    enderecos: {
      type: Array as () => IPessoaEndereco[],
      required: true,
    },
    relacoes: {
      type: Array as () => number[],
      required: true,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    Icone,
    EnderecoPessoaModal,
    ApresentarCidade,
  },
  emits: ['update:enderecos'],
  setup(props, { emit }) {
    const { montaOpcoesComListaItemGenerico } = useTelaBase();
    const servicoPessoa = new ServicoPessoa();

    const state = reactive({
      exibirEnderecoModal: false,
      enderecoSelecionado: {} as IPessoaEndereco,
      listaTiposEndereco: [] as IOption[],
      listaCidades: [] as IItemConsultaRapida[],
      colunasGrade: [] as IColumn[],
      windowWidth: (window.innerWidth - 100),
    });
    state.listaCidades = [];

    const computedEnderecosPessoa = computed({
      get: () => props.enderecos,
      set: (valor: IPessoaEndereco[]) => {
        emit('update:enderecos', valor);
      },
    });

    state.colunasGrade = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 0, visible: false, ellipsis: true,
      },
      {
        title: 'Tipo', dataIndex: 'tipoEndereco', key: 'TipoEndereco', position: 2, visible: true, width: 90, ordering: false, align: 'left', ellipsis: true,
      },
      {
        title: 'Identificação', dataIndex: 'identificacao', key: 'Identificacao', position: 3, visible: true, width: 200, ordering: false, align: 'left', ellipsis: true,
      },
      {
        title: 'Logradouro', key: 'Logradouro', position: 4, visible: true, ordering: false, align: 'left', width: 300, ellipsis: true,
      },
      {
        title: 'Número', key: 'Numero', position: 5, visible: true, ordering: false, align: 'left', width: 70, ellipsis: true,
      },
      {
        title: 'Complemento', key: 'Complemento', position: 6, visible: true, align: 'left', width: 130, ellipsis: true,
      },
      {
        title: 'Bairro', key: 'Bairro', position: 7, visible: true, ordering: false, align: 'left', width: 150, ellipsis: true,
      },
      {
        title: 'Cidade/Estado', key: 'CidadeEstado', position: 8, visible: true, ordering: false, align: 'left', ellipsis: true,
      },
      {
        title: 'Ações', key: 'acoes', position: 1, visible: true, width: 70, align: 'center', ellipsis: true,
      },
    ];

    (async () => {
      const listaTiposEndereco = await servicoPessoa.obterListaTipoEndereco();
      state.listaTiposEndereco = montaOpcoesComListaItemGenerico(listaTiposEndereco);
    })();

    async function salvarEndereco(tipoOperacao:ETipoOperacao, pessoaEndereco: IPessoaEndereco) {
      if (tipoOperacao === ETipoOperacao.Incluir) {
        computedEnderecosPessoa.value.push(pessoaEndereco);
      } else if (tipoOperacao === ETipoOperacao.Alterar) {
        const indexEndereco = computedEnderecosPessoa.value.findIndex((c) => c.codigo === pessoaEndereco.codigo);
        if (indexEndereco >= 0) {
          computedEnderecosPessoa.value[indexEndereco].codigo = pessoaEndereco.codigo;
          computedEnderecosPessoa.value[indexEndereco].identificacao = pessoaEndereco.identificacao;
          computedEnderecosPessoa.value[indexEndereco].tipoEndereco = pessoaEndereco.tipoEndereco;
          computedEnderecosPessoa.value[indexEndereco].endereco.codigo = pessoaEndereco.endereco.codigo;
          computedEnderecosPessoa.value[indexEndereco].endereco.cep = pessoaEndereco.endereco.cep;
          computedEnderecosPessoa.value[indexEndereco].endereco.logradouro = pessoaEndereco.endereco.logradouro;
          computedEnderecosPessoa.value[indexEndereco].endereco.bairro = pessoaEndereco.endereco.bairro;
          computedEnderecosPessoa.value[indexEndereco].endereco.numero = pessoaEndereco.endereco.numero;
          computedEnderecosPessoa.value[indexEndereco].endereco.complemento = pessoaEndereco.endereco.complemento;
          computedEnderecosPessoa.value[indexEndereco].endereco.codigoCidade = pessoaEndereco.endereco.codigoCidade;
        }
      }
    }

    function novoEndereco() {
      state.enderecoSelecionado = {} as IPessoaEndereco;
      state.exibirEnderecoModal = true;
    }
    function editarEndereco(pessoaEndereco: IPessoaEndereco) {
      state.enderecoSelecionado = pessoaEndereco;
      state.exibirEnderecoModal = true;
    }

    function removerEndereco(codigoEndereco: number) {
      const index = computedEnderecosPessoa.value.findIndex((c) => c.codigo === codigoEndereco);
      if (index !== -1) {
        computedEnderecosPessoa.value.splice(index, 1);
      }
    }

    function confirmaExclusao(pessoaEndereco: IPessoaEndereco) {
      Modal.confirm({
        title: 'Você deseja remover o endereço:',
        content: `${pessoaEndereco.identificacao} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => { removerEndereco(pessoaEndereco.codigo); },
      });
    }

    function obterDescricaoTipoEndereco(tipoEndereco: number): string {
      if (UtilitarioGeral.validaLista(state.listaTiposEndereco)) {
        const tipoEncontrado = state.listaTiposEndereco.find((c) => c.value === tipoEndereco);
        if (tipoEncontrado !== undefined) {
          return tipoEncontrado.label;
        }
      }

      return '';
    }

    return {
      props,
      computedEnderecosPessoa,
      state,
      salvarEndereco,
      novoEndereco,
      editarEndereco,
      confirmaExclusao,
      obterDescricaoTipoEndereco,
    };
  },
});
