
import { computed, defineComponent, reactive } from 'vue';
import { IClienteFinanceiro } from '@/models/Entidades/Cadastros/Pessoas/Clientes/IClienteFinanceiro';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IOption } from '@/core/models/AntDesign/IOption';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';

export default defineComponent({
  name: 'DadosFinanceiroCliente',
  props: {
    financeiro: {
      type: Object as ()=> IClienteFinanceiro,
      required: true,
    },
  },
  components: {
    CampoNumerico,
    SelecionarData,
  },
  emits: ['update:financeiro'],
  setup(props, { emit }) {
    const { montaOpcoesComListaItemGenerico } = useTelaBase();

    const servicoPessoa = new ServicoPessoa();

    const state = reactive({
      listaProtestarTitulos: [] as IOption[],
    });

    const computedFinanceiro = computed({
      get: () => props.financeiro,
      set: (valor: IClienteFinanceiro) => {
        emit('update:financeiro', valor);
      },
    });

    (async () => {
      const listaProtestarTitulos = await servicoPessoa.obterListaProtestarTitulos();
      state.listaProtestarTitulos = montaOpcoesComListaItemGenerico(listaProtestarTitulos);
    })();

    return {
      computedFinanceiro,
      state,
    };
  },
});
