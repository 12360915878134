import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card = _resolveComponent("card")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_display_grupo_campos = _resolveComponent("display-grupo-campos")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!

  return (_openBlock(), _createBlock(_component_a_skeleton, {
    active: "",
    loading: _ctx.state.carregando,
    paragraph: { rows: 2 }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tabs, { defaultActiveKey: "1" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_tab_pane, { tab: "Dados Principais" }, {
            default: _withCtx(() => [
              _createVNode(_component_card, { titulo: " " }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "AbaPrincipal")
                ]),
                _: 3
              })
            ]),
            _: 3
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.abas, (grupo, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: index+1
            }, [
              (grupo.ativo)
                ? (_openBlock(), _createBlock(_component_a_tab_pane, {
                    forceRender: "true",
                    key: index+1,
                    tab: grupo.titulo
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_card, { titulo: " " }, {
                        default: _withCtx(() => [
                          _createVNode(_component_display_grupo_campos, {
                            codigoRegistro: _ctx.props.codigoRegistro,
                            grupo: _ctx.state.abas[index]
                          }, null, 8, ["codigoRegistro", "grupo"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["tab"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128)),
          (_ctx.state.paineis.length > 0)
            ? (_openBlock(), _createBlock(_component_a_tab_pane, {
                tab: "Campos Personalizados",
                forceRender: "true",
                key: _ctx.props.personalizacao.gruposCamposPersonalizados.length+1
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.paineis, (grupo, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (grupo.ativo)
                        ? (_openBlock(), _createBlock(_component_card, {
                            key: 0,
                            titulo: grupo.titulo
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_display_grupo_campos, {
                                ref_for: true,
                                ref: "refDisplayGrupoCampos",
                                codigoRegistro: _ctx.props.codigoRegistro,
                                grupo: _ctx.state.paineis[index]
                              }, null, 8, ["codigoRegistro", "grupo"])
                            ]),
                            _: 2
                          }, 1032, ["titulo"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["loading"]))
}