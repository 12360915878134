export enum ERelacaoArquivo {
    Sistema = 1,
    Anexos = 2,
    Relatorios = 3,
    DocumentosFiscais = 4,
    Financeiro = 5,
    ImportacaoArquivos = 6,
    FotoPessoa = 7,
    FotoProduto = 8,
}
