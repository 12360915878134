import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-24 ss-display-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_display_campo_personalizado = _resolveComponent("display-campo-personalizado")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!

  return (_openBlock(), _createBlock(_component_a_skeleton, {
    active: "",
    loading: _ctx.state.carregando,
    paragraph: { rows: 0 }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.linha.colunas, (coluna, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              style: {"width":"100%"}
            }, [
              _createVNode(_component_display_campo_personalizado, {
                "codigo-registro": _ctx.props.codigoRegistro,
                campo: _ctx.props.linha.colunas[index].campoPersonalizado
              }, null, 8, ["codigo-registro", "campo"])
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}