import ApiUtilitarios from '@/core/conectores/ApiUtilitarios';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IDTORetornoConsultaCEP } from '@/models/DTO/Integracoes/IDTORetornoConsultaCEP';
import { IDTORetornoConsultaCNPJ } from '@/models/DTO/Integracoes/IDTORetornoConsultaCNPJ';
import { IDTORetornoValidacaoCPFCNPJ } from '@/models/DTO/Integracoes/IDTORetornoValidacaoCPFCNPJ';
import { IDTODadosCalculoTributacao } from '@/models/DTO/Tributacoes/IDTODadosCalculoTributacao';
import { IDTODadosCalculoTributacaoServico } from '@/models/DTO/Tributacoes/IDTODadosCalculoTributacaoServico';
import { IDTORetornoCalculoTributacao } from '@/models/DTO/Tributacoes/IDTORetornoCalculoTributacao';
import { IDTORetornoCalculoTributacaoServico } from '@/models/DTO/Tributacoes/IDTORetornoCalculoTributacaoServico';

class ServicoUtilitario {
    private endPoint = 'utilitarios';

    private apiUtilitarios = new ApiUtilitarios();

    public async obterIdContratante(urlContratante: string):Promise<IRetornoRequisicao> {
      const result: any = await this.apiUtilitarios.get(`${this.endPoint}/identificador-contratante/${urlContratante}`);
      return result.data;
    }

    public async obterUrlContratante(identificadorContratante: string):Promise<IRetornoRequisicao> {
      const result: any = await this.apiUtilitarios.get(`${this.endPoint}/url-contratante/${identificadorContratante}`);
      return result.data;
    }

    public async obtemTamanhoArquivo(tamanhoBytes: number):Promise<string> {
      const result: any = await this.apiUtilitarios.get(`${this.endPoint}/tamanho-arquivo/${tamanhoBytes}`);
      return result.data;
    }

    public async consultaCNPJ(cnpj: string):Promise<IDTORetornoConsultaCNPJ> {
      let retornoConsultaCNPJ:IDTORetornoConsultaCNPJ = {} as IDTORetornoConsultaCNPJ;
      retornoConsultaCNPJ.cnpj = UtilitarioMascara.removerMascara(cnpj);
      if (UtilitarioGeral.validaValorComLimiteCaracteres(retornoConsultaCNPJ.cnpj, 14)) {
        const result: any = await this.apiUtilitarios.get(`${this.endPoint}/consulta-cnpj/${retornoConsultaCNPJ.cnpj}`);
        retornoConsultaCNPJ = result.data;
      }
      return retornoConsultaCNPJ;
    }

    public async consultaCNAE(valor: string):Promise<IItemGenerico[]> {
      let listaCNAES:IItemGenerico[] = [] as IItemGenerico[];
      if (UtilitarioGeral.valorValido(valor)) {
        const result: any = await this.apiUtilitarios.get(`${this.endPoint}/consulta-cnae/${valor}`);
        listaCNAES = result.data;
      }
      return listaCNAES;
    }

    public async consultaCEST(valor: string):Promise<IItemGenerico[]> {
      let listaCESTs:IItemGenerico[] = [] as IItemGenerico[];
      if (UtilitarioGeral.valorValido(valor)) {
        const result: any = await this.apiUtilitarios.get(`${this.endPoint}/consulta-cest/${valor}`);
        listaCESTs = result.data;
      }
      return listaCESTs;
    }

    public async consultaGeneroItem(valor: string):Promise<IItemGenerico[]> {
      let listaGeneros:IItemGenerico[] = [] as IItemGenerico[];
      if (UtilitarioGeral.valorValido(valor)) {
        const result: any = await this.apiUtilitarios.get(`${this.endPoint}/consulta-genero-item/${valor}`);
        listaGeneros = result.data;
      }
      return listaGeneros;
    }

    public async consultaCEP(cep: string):Promise<IDTORetornoConsultaCEP> {
      let retornoConsultaCEP:IDTORetornoConsultaCEP = {} as IDTORetornoConsultaCEP;
      retornoConsultaCEP.cep = UtilitarioMascara.removerMascara(cep);
      if (UtilitarioGeral.validaValorComLimiteCaracteres(retornoConsultaCEP.cep, 8)) {
        const result: any = await this.apiUtilitarios.get(`${this.endPoint}/consulta-cep/${retornoConsultaCEP.cep}`);
        retornoConsultaCEP = result.data;
      }
      return retornoConsultaCEP;
    }

    public async calcularTributacoes(dadosCalculo: IDTODadosCalculoTributacao): Promise<IDTORetornoCalculoTributacao> {
      const result: any = await this.apiUtilitarios.post(`${this.endPoint}/calcular-tributacoes`, dadosCalculo);
      return result.data;
    }

    public async calcularTributacoesServico(dadosCalculo: IDTODadosCalculoTributacaoServico): Promise<IDTORetornoCalculoTributacaoServico> {
      const result: any = await this.apiUtilitarios.post(`${this.endPoint}/calcular-tributacoes/servico`, dadosCalculo);
      return result.data;
    }

    public async validarCpfCnpj(cpfCnpj: string): Promise<IDTORetornoValidacaoCPFCNPJ> {
      const result: any = await this.apiUtilitarios.post(`${this.endPoint}/validar-cpf-cnpj/${cpfCnpj}`);
      return result.data;
    }
}

export default ServicoUtilitario;
