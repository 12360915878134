
import { computed, defineComponent, ref } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useSelecionarBase } from '@/core/composables/SelecionarBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoBanco from '@/servicos/Cadastros/Financeiro/ServicoBanco';

export default defineComponent({
  name: 'SelecionarBanco',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    nomeSelecionado: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Digite para selecionar',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    dataAttributeColuna: {
      type: String,
      default: '',
    },
    classCss: {
      type: String,
      default: '',
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
    omitirBancos: {
      type: Array as () => number[],
    },
  },
  components: {
    Icone,
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:nomeSelecionado', 'change'],
  setup(props, { emit }) {
    const {
      selecionarBase, montaOpcoesComListaConsultaRapida, verificacaoPreBusca, verificacaoUltimaPesquisa, aguardarConclusaoCarregamento,
      comportamentoPadraoSemResultado, instanciaParametrosConsultaRapidaPorCodigo, instanciaParametrosConsultaRapidaPesquisa,
    } = useSelecionarBase(props, emit);

    let debounce = 0;
    const servicoBanco = new ServicoBanco();
    const logoBanco = ref('');

    function preencheListaOpcoes():void {
      if (UtilitarioGeral.validaLista(selecionarBase.listaItensConsultaRapida)) {
        if (props.omitirBancos !== undefined) {
          props.omitirBancos.forEach((codigoBanco) => {
            selecionarBase.listaItensConsultaRapida = selecionarBase.listaItensConsultaRapida.filter((c) => c.codigo !== codigoBanco);
          });
        }
        selecionarBase.listaOpcoes = montaOpcoesComListaConsultaRapida(selecionarBase.listaItensConsultaRapida);
      }
    }

    async function obterBancoPorCodigo(valor: any) {
      if (!verificacaoPreBusca(valor)) return;

      selecionarBase.listaOpcoes = [];

      const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPorCodigo(valor, true);
      selecionarBase.listaItensConsultaRapida = await servicoBanco.consultaRapida(parametrosConsultaRapida);
      preencheListaOpcoes();
      comportamentoPadraoSemResultado();
    }

    async function pesquisarBanco(valorPesquisa: any) {
      selecionarBase.emDigitacao = true;

      if (!verificacaoUltimaPesquisa(valorPesquisa)) return;

      selecionarBase.buscandoDados = true;
      selecionarBase.listaOpcoes = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPesquisa(valorPesquisa, true);
        selecionarBase.listaItensConsultaRapida = await servicoBanco.consultaRapida(parametrosConsultaRapida);
        preencheListaOpcoes();
        selecionarBase.valorUltimaPesquisa = valorPesquisa;
        selecionarBase.buscandoDados = false;
      }, 600);
    }

    const computedNomeSelecionado = computed({
      get: () => props.nomeSelecionado,
      set: (val: string) => {
        emit('update:nomeSelecionado', val);
      },
    });

    function preencherNomeBanco(codigoBanco:number) {
      const option = selecionarBase.listaOpcoes.find((c) => c.value === codigoBanco);
      if (option?.label !== undefined && option?.label !== '') {
        computedNomeSelecionado.value = option?.label;
      } else {
        computedNomeSelecionado.value = '';
      }
    }

    function preencherLogoBanco(codigoBanco:number) {
      const itemConsultaRapida = selecionarBase.listaItensConsultaRapida.find((c) => c.codigo === codigoBanco);
      if (itemConsultaRapida !== undefined) {
        if (UtilitarioGeral.valorValido(itemConsultaRapida.informacaoAdicional)) {
          logoBanco.value = itemConsultaRapida.informacaoAdicional;
          return;
        }
      }
      logoBanco.value = '';
    }

    function obterBanco():any {
      if (props.codigoSelecionado === 0) {
        return undefined;
      }
      obterBancoPorCodigo(props.codigoSelecionado);
      if (props.codigoSelecionado !== undefined && props.codigoSelecionado > 0) {
        preencherNomeBanco(props.codigoSelecionado);
        preencherLogoBanco(props.codigoSelecionado);
      }
      return props.codigoSelecionado;
    }

    const computedCodigoSelecionado = computed({
      get: () => obterBanco(),
      set: (val: number) => {
        preencherNomeBanco(val);
        preencherLogoBanco(val);
        emit('update:codigoSelecionado', val);
      },
    });

    function obterBancos():number[] {
      obterBancoPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterBancos(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    return {
      props,
      selecionarBase,
      UtilitarioGeral,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      pesquisarBanco,
      aguardarConclusaoCarregamento,
      change,
      logoBanco,
    };
  },
});
