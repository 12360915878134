
import { computed, defineComponent, reactive } from 'vue';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import ServicoCidade from '@/servicos/Cadastros/Localizacoes/ServicoCidade';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'ApresentarCidade',
  props: {
    codigo: {
      type: Number || undefined,
    },
  },
  emits: ['update:codigo'],
  setup(props, { emit }) {
    const servicoCidade = new ServicoCidade();

    const state = reactive({
      nomeCidade: '',
    });

    async function obterCidadePorCodigo(valor: any) {
      if (props.codigo === 0) { return; }

      state.nomeCidade = '';
      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        apenasAtivos: false, recursoAssociado: '',
      };

      parametrosConsultaRapida.filtrarPorCodigo = true;
      parametrosConsultaRapida.valor = valor;

      const listaCidades = await servicoCidade.consultaRapida(parametrosConsultaRapida, true);
      if (UtilitarioGeral.validaLista(listaCidades)) {
        state.nomeCidade = listaCidades[0].textoIdentificacao;
      }
    }
    function obterCidade():any {
      obterCidadePorCodigo(props.codigo);
      return props.codigo;
    }

    const computedCodigo = computed({
      get: () => obterCidade(),
      set: (val: number) => {
        emit('update:codigo', val);
      },
    });

    return {
      props,
      state,
      computedCodigo,
    };
  },
});
