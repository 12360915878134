
import { defineComponent, reactive } from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoTelefone from '@/core/components/Tela/CampoTelefone.vue';
import SelecionarTipoContato from '@/components/Cadastros/Pessoas/SelecionarTipoContato.vue';
import { IPessoaContato } from '@/models/Entidades/Cadastros/Pessoas/IPessoaContato';
import { ETipoOperacao } from '@/core/models/Enumeradores/ETipoOperacao';
import { IContato } from '@/models/Entidades/Cadastros/Pessoas/Contatos/IContato';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import { IContatoPreferencias } from '@/models/Entidades/Cadastros/Pessoas/Contatos/IContatoPreferencias';
import { IContatoPermissao } from '@/models/Entidades/Cadastros/Pessoas/Contatos/IContatoPermissao';
import { IContatoTelefone } from '@/models/Entidades/Cadastros/Pessoas/Contatos/IContatoTelefone';
import { ETipoTelefone } from '@/models/Enumeradores/Compartilhados/ETipoTelefone';
import CampoCpfCnpj from '@/core/components/Tela/CampoCpfCnpj.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { useModalBase } from '@/core/composables/ModalBase';

export default defineComponent({
  name: 'ContatoPessoaModal',
  components: {
    Icone,
    SelecionarTipoContato,
    CampoTelefone,
    CampoCpfCnpj,
    Card,
    RequisicaoModal,
  },
  props: {
    empresas: {
      type: Array as () => number[],
      default: () => [],
    },
    relacoes: {
      type: Array as () => number[],
      default: () => [],
    },
  },
  emits: ['salvarContato'],
  setup(props, { emit }) {
    const { montaOpcoesComListaItemGenerico } = useTelaBase();
    const { modalBase, apresentarRetornoRequisicao } = useModalBase(props, emit);
    const servicoPessoa = new ServicoPessoa();

    const state = reactive({
      visivel: false,
      tipoOperacao: {} as ETipoOperacao,
      pessoaContato: {} as IPessoaContato,
      listaTiposTelefone: [] as IOption[],
      listaTiposPreferenciaContato: [] as IOption[],
    });

    (async () => {
      const listaTiposTelefone = await servicoPessoa.obterListaTipoTelefone();
      state.listaTiposTelefone = montaOpcoesComListaItemGenerico(listaTiposTelefone);

      const listaTiposPreferenciaContato = await servicoPessoa.obterListaTipoPreferenciaContato();
      state.listaTiposPreferenciaContato = montaOpcoesComListaItemGenerico(listaTiposPreferenciaContato);
    })();

    function obterDescricaoTipoTelefone(tipoTelefone:number): string {
      const tipo = state.listaTiposTelefone.find((c) => c.value === tipoTelefone);
      if (tipo !== undefined) {
        return tipo.label;
      }

      return '';
    }

    function obterIconeTipoTelefone(tipoTelefone:number): string {
      if (tipoTelefone === ETipoTelefone.Fixo) {
        return 'telefone';
      } if (tipoTelefone === ETipoTelefone.Celular) {
        return 'celular';
      } if (tipoTelefone === ETipoTelefone.WhatsApp) {
        return 'whatsapp';
      } if (tipoTelefone === ETipoTelefone.Telegram) {
        return 'telegram';
      }
      return '';
    }

    function adicionarTelefone(tipoTelefone: number) {
      const telefone:IContatoTelefone = {} as IContatoTelefone;
      telefone.codigo = new Date().getTime();
      telefone.tipoTelefone = tipoTelefone;
      telefone.codigoContato = 0;
      state.pessoaContato.contato.telefones.push(telefone);
    }

    function removerTelefone(codigo: number) {
      const indexTelefone = state.pessoaContato.contato.telefones.findIndex((c) => c.codigo === codigo);
      if (indexTelefone !== -1) {
        state.pessoaContato.contato.telefones.splice(indexTelefone, 1);
      }
    }

    function novoContato() {
      const pessoaContato: IPessoaContato = {} as IPessoaContato;
      pessoaContato.codigo = new Date().getTime();
      pessoaContato.contato = {} as IContato;
      pessoaContato.contato.permissao = {} as IContatoPermissao;
      pessoaContato.contato.preferencias = {} as IContatoPreferencias;
      pessoaContato.contato.telefones = [] as IContatoTelefone[];
      state.pessoaContato = pessoaContato;
      state.tipoOperacao = ETipoOperacao.Incluir;
      state.visivel = true;
    }

    function editarContato(pessoaContato: IPessoaContato) {
      state.pessoaContato = UtilitarioGeral.instanciaObjetoLocal(pessoaContato);
      state.tipoOperacao = ETipoOperacao.Alterar;
      state.visivel = true;
    }

    async function salvarContato() {
      const retornoValidacao = await servicoPessoa.validarContato(state.pessoaContato);

      if (retornoValidacao.status === EStatusRetornoRequisicao.Sucesso) {
        emit('salvarContato', state.tipoOperacao, state.pessoaContato);
        state.visivel = false;
      } else {
        apresentarRetornoRequisicao(retornoValidacao);
      }
    }

    return {
      state,
      props,
      UtilitarioMascara,
      ETipoTelefone,
      novoContato,
      editarContato,
      salvarContato,
      obterDescricaoTipoTelefone,
      obterIconeTipoTelefone,
      adicionarTelefone,
      removerTelefone,
      modalBase,
    };
  },
});
