
import { defineComponent, reactive, watch } from 'vue';
import SelecionarCidade from '@/components/Cadastros/Localizacoes/Cidades/SelecionarCidade.vue';
import ServicoUtilitario from '@/servicos/MeuSistema/ServicoUtilitario';
import ServicoCidade from '@/servicos/Cadastros/Localizacoes/ServicoCidade';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import Icone from '@/core/components/Icone.vue';
import { IPessoaEndereco } from '@/models/Entidades/Cadastros/Pessoas/IPessoaEndereco';
import { ETipoOperacao } from '@/core/models/Enumeradores/ETipoOperacao';
import { IEndereco } from '@/models/Entidades/Cadastros/Localizacoes/IEndereco';
import { useModalBase } from '@/core/composables/ModalBase';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import CampoCep from '@/core/components/Tela/CampoCep.vue';
import SelecionarTipoEndereco from '@/components/Cadastros/Pessoas/SelecionarTipoEndereco.vue';
import { ETipoEndereco } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoEndereco';

export default defineComponent({
  name: 'EnderecoPessoaModal',
  components: {
    Icone,
    CampoCep,
    SelecionarCidade,
    SelecionarTipoEndereco,
    RequisicaoModal,
  },
  props: {
    visivel: {
      type: Boolean,
    },
    relacoes: {
      type: Array as () => number[],
      required: true,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    endereco: {
      type: Object as () => IPessoaEndereco,
      required: true,
    },
  },
  emits: ['update:visivel', 'salvarEndereco'],
  setup(props, { emit }) {
    const { modalBase, apresentarRetornoRequisicao } = useModalBase(props, emit);

    const servicoUtilitario = new ServicoUtilitario();
    const servicoCidade = new ServicoCidade();
    const servicoPessoa = new ServicoPessoa();

    const state = reactive({
      tipoOperacao: {} as ETipoOperacao,
      pessoaEndereco: {} as IPessoaEndereco,
    });

    async function pesquisarCidade(nomeCidade: string, uf: string) {
      state.pessoaEndereco.endereco.codigoCidade = 0;
      const cidade = await servicoCidade.obterCidadePorNomeUf(nomeCidade, uf);
      if (UtilitarioGeral.objetoValido(cidade)) {
        if (UtilitarioGeral.validaCodigo(cidade.codigo)) {
          state.pessoaEndereco.endereco.codigoCidade = cidade.codigo;
        }
      }
    }

    async function consultaCEP(cep:string) {
      const retornoConsultaCEP = await servicoUtilitario.consultaCEP(cep);
      if (UtilitarioGeral.objetoValido(retornoConsultaCEP)) {
        if (UtilitarioGeral.valorValido(retornoConsultaCEP.cidade)) {
          state.pessoaEndereco.endereco.logradouro = retornoConsultaCEP.logradouro;
          state.pessoaEndereco.endereco.bairro = retornoConsultaCEP.bairro;
          if (UtilitarioGeral.valorValido(retornoConsultaCEP.complemento)) {
            state.pessoaEndereco.endereco.complemento = retornoConsultaCEP.complemento;
          }
          await pesquisarCidade(retornoConsultaCEP.cidade, retornoConsultaCEP.uf);
        }
      }
    }

    async function salvarEndereco() {
      const retornoValidacao = await servicoPessoa.validarEndereco(state.pessoaEndereco);
      if (retornoValidacao.status === EStatusRetornoRequisicao.Sucesso) {
        emit('salvarEndereco', state.tipoOperacao, state.pessoaEndereco);
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retornoValidacao);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      if (modalBase.computedVisivel) {
        if (props.endereco.codigo > 0) {
          state.pessoaEndereco = UtilitarioGeral.instanciaObjetoLocal(props.endereco);
          state.tipoOperacao = ETipoOperacao.Alterar;
        } else {
          const pessoaEndereco: IPessoaEndereco = {} as IPessoaEndereco;
          pessoaEndereco.tipoEndereco = ETipoEndereco.Principal;
          pessoaEndereco.identificacao = 'PRINCIPAL';
          pessoaEndereco.codigo = new Date().getTime();
          pessoaEndereco.endereco = {} as IEndereco;
          state.pessoaEndereco = pessoaEndereco;
          state.tipoOperacao = ETipoOperacao.Incluir;
        }
      }
    });

    return {
      state,
      props,
      modalBase,
      consultaCEP,
      salvarEndereco,
    };
  },
});
