
import { computed, defineComponent, reactive } from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import { useTelaBase } from '@/core/composables/TelaBase';

export default defineComponent({
  name: 'SelecionarTipoEndereco',
  props: {
    codigoSelecionado: {
      type: Number || undefined,
    },
    relacoes: {
      type: Array as () => number[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoSelecionado'],
  setup(props, { emit }) {
    const servicoPessoa = new ServicoPessoa();
    const { montaOpcoesComListaItemGenerico } = useTelaBase();
    const state = reactive({
      listaTiposEndereco: [] as IOption[],
      relacoesFiltradas: [] as number[],
    });
    state.relacoesFiltradas = [];

    async function obterTipoEnderecoPorCodigo() {
      if (state.relacoesFiltradas !== props.relacoes) {
        const listaTiposEndereco = await servicoPessoa.obterListaTipoEndereco(props.relacoes);
        state.listaTiposEndereco = montaOpcoesComListaItemGenerico(listaTiposEndereco);

        state.relacoesFiltradas = props.relacoes;
      }
    }

    async function obterListaTipos() {
      if (!UtilitarioGeral.validaLista(state.listaTiposEndereco) && state.relacoesFiltradas !== props.relacoes) {
        const listaTiposEndereco = await servicoPessoa.obterListaTipoEndereco(props.relacoes);
        state.listaTiposEndereco = montaOpcoesComListaItemGenerico(listaTiposEndereco);
      }
    }

    function obterTipoEndereco():any {
      obterTipoEnderecoPorCodigo();
      return props.codigoSelecionado;
    }

    const computedCodigoSelecionado = computed({
      get: () => obterTipoEndereco(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
      },
    });

    return {
      props,
      state,
      obterListaTipos,
      computedCodigoSelecionado,
    };
  },
});
