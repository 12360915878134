
import { computed, defineComponent } from 'vue';
import { IPessoaInscricoes } from '@/models/Entidades/Cadastros/Pessoas/IPessoaInscricoes';
import { ETipoInscricaoEstadual } from '@/models/Enumeradores/Compartilhados/ETipoInscricaoEstadual';
import SelecionarTipoInscricaoEstadual from '@/components/Compartilhados/SelecionarTipoInscricaoEstadual.vue';

export default defineComponent({
  name: 'InscricoesPessoa',
  props: {
    inscricoes: {
      type: Object as ()=> IPessoaInscricoes,
      required: true,
    },
  },
  components: {
    SelecionarTipoInscricaoEstadual,
  },
  emits: ['update:inscricoes'],
  setup(props, { emit }) {
    const computedInscricoes = computed({
      get: () => props.inscricoes,
      set: (valor: IPessoaInscricoes) => {
        emit('update:inscricoes', valor);
      },
    });

    function verificaPreenchimentoIE(tipoIE: number) {
      if (tipoIE === ETipoInscricaoEstadual.ContribuinteIsento || tipoIE === ETipoInscricaoEstadual.NaoContribuinte) {
        computedInscricoes.value.inscricaoEstadual = '';
      }
    }
    return {
      computedInscricoes,
      ETipoInscricaoEstadual,
      verificaPreenchimentoIE,
    };
  },
});
