
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiro.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { ESazonalidade } from '@/core/models/Enumeradores/ESazonalidade';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import SelecionarSazonalidade from '@/core/components/Tela/SelecionarSazonalidade.vue';
import { IFormaPagamento } from '@/models/Entidades/Cadastros/Financeiro/IFormaPagamento';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { INotaFiscalServicoDetalhePagamento } from '@/models/Entidades/Fiscal/INotaFiscalServico';

export default defineComponent({
  name: 'NotaFiscalServicoDetalhePagamentoModal',
  props: {
    classeCss: {
      type: String,
      required: true,
    },
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
    detalhePagamento: {
      type: Object as () => INotaFiscalServicoDetalhePagamento,
      required: true,
    },
    detalhesPagamento: {
      type: Array as () => INotaFiscalServicoDetalhePagamento[],
      default: [] as INotaFiscalServicoDetalhePagamento[],
    },
    formaPagamento: {
      type: Object as () => IFormaPagamento,
      default: {} as IFormaPagamento,
    },
  },
  components: {
    Card,
    Icone,
    SelecionarTipoDocumentoFinanceiro,
    SelecionarData,
    CampoNumerico,
    SelecionarConta,
    SelecionarCategoriaPlanoConta,
    SelecionarSazonalidade,
  },
  emits: ['update:visivel', 'salvar'],
  setup(props, { emit }) {
    const servicoNotaFiscal = new ServicoNotaFiscal();
    servicoNotaFiscal.requisicaoSistema();
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      detalhePagamento: {} as INotaFiscalServicoDetalhePagamento,
      detalhesPagamento: [] as INotaFiscalServicoDetalhePagamento[],
      repetir: false,
      sazonalidade: ESazonalidade.mensal,
      sazonalidadeCustomizada: 30,
      repeticao: 0,
      ultimoVencimento: '',
      mostrarCentroCusto: true,
      telaMobile: false,
    });

    function limparTela() {
      state.detalhePagamento = {} as INotaFiscalServicoDetalhePagamento;
      state.detalhePagamento.alterado = false;
      state.repetir = false;
      state.sazonalidade = ESazonalidade.mensal;
      state.sazonalidadeCustomizada = 0;
      state.repeticao = 0;
      state.ultimoVencimento = '';
      state.mostrarCentroCusto = true;
    }

    function obterSazonalidade() {
      switch (state.sazonalidade) {
        case ESazonalidade.semanal:
          return 7;
        case ESazonalidade.quinzenal:
          return 15;
        case ESazonalidade.mensal:
          return 30;
        case ESazonalidade.bimestral:
          return 60;
        case ESazonalidade.trimestral:
          return 90;
        case ESazonalidade.semestral:
          return 180;
        case ESazonalidade.anual:
          return 360;
        default:
          return state.sazonalidadeCustomizada;
      }
    }

    function atualizarUltimoVencimento() {
      if (state.detalhePagamento.data === undefined) {
        state.ultimoVencimento = '';
        return;
      }
      const sazonalidade = obterSazonalidade();
      let tempoTotal = 0;
      let ultimaData = UtilitarioData.converterDataParaDayJs(state.detalhePagamento.data);
      for (let contador = 1; contador <= state.repeticao; contador += 1) {
        tempoTotal += sazonalidade;
      }
      ultimaData = UtilitarioData.adicionarDiaNaDataDayJs(ultimaData, tempoTotal);
      state.ultimoVencimento = UtilitarioData.aplicaFormatoData(ultimaData);
    }

    function obterMensagemRepeticao() {
      const valorTotal = UtilitarioMascara.mascararValor(state.detalhePagamento.valorPagamento + (state.repeticao * state.detalhePagamento.valorPagamento), 2, true);
      if (state.repeticao === 1) {
        return `Será criado 1 detalhe de pagamento adicional, totalizando ${valorTotal}.\nO último vencimento será: ${state.ultimoVencimento}.`;
      }
      return `Serão criados ${state.repeticao} detalhes de pagamento adicionais, totalizando ${valorTotal}.\nO último vencimento será: ${state.ultimoVencimento}.`;
    }

    function checkRepetir() {
      if (state.repetir) {
        state.repeticao = 1;
        state.detalhePagamento.parcela = 1;
        atualizarUltimoVencimento();
      } else {
        state.repeticao = 0;
        state.detalhePagamento.parcela = 0;
      }
    }

    function validarCampos() {
      if (state.detalhePagamento.codigoTipoDocumentoFinanceiro === 0 || state.detalhePagamento.codigoTipoDocumentoFinanceiro === undefined) {
        apresentarMensagemAlerta('O Tipo de Documento deve ser informado!');
        return false;
      }

      if (state.detalhePagamento.codigoConta === 0 || state.detalhePagamento.codigoConta === undefined) {
        apresentarMensagemAlerta('A Conta deve ser informada!');
        return false;
      }

      if (state.detalhePagamento.codigoPlanoContaCategoria === 0 || state.detalhePagamento.codigoPlanoContaCategoria === undefined) {
        apresentarMensagemAlerta('A Categoria do Plano de Contas deve ser informada!');
        return false;
      }

      if (state.detalhePagamento.numeroTitulo === '' || state.detalhePagamento.numeroTitulo === undefined) {
        apresentarMensagemAlerta('O Número do Título deve ser informado!');
        return false;
      }

      if (state.detalhePagamento.data === '' || state.detalhePagamento.data === undefined) {
        apresentarMensagemAlerta('A Data deve ser informada!');
        return false;
      }

      if (state.detalhePagamento.valorPagamento === 0 || state.detalhePagamento.valorPagamento === undefined) {
        apresentarMensagemAlerta('O Valor deve ser maior que 0!');
        return false;
      }

      return true;
    }

    function obterParcelas() {
      const parcelas = [] as INotaFiscalServicoDetalhePagamento[];
      const numeroTitulo = state.detalhePagamento.numeroTitulo;
      state.detalhePagamento.numeroTitulo = `${state.detalhePagamento.numeroTitulo}/1`;
      state.detalhePagamento.parcela = 1;
      state.detalhePagamento.alterado = true;
      parcelas.push(UtilitarioGeral.clonarObjeto(state.detalhePagamento));
      let numeroParcela = 2;
      while (numeroParcela <= state.repeticao + 1) {
        const parcela = UtilitarioGeral.clonarObjeto(state.detalhePagamento);
        parcela.parcela = numeroParcela;
        parcela.numeroTitulo = `${numeroTitulo}/${numeroParcela}`;
        parcela.data = UtilitarioData.adicionarDiaNaData(state.detalhePagamento.data, (numeroParcela - 1) * obterSazonalidade());
        parcela.valorTotal = parcela.valorPagamento;
        parcela.alterado = true;
        parcelas.push(parcela);
        numeroParcela += 1;
      }
      return parcelas;
    }

    function obterParcelasAtualizadas() {
      let parcelas = [] as INotaFiscalServicoDetalhePagamento[];
      parcelas = UtilitarioGeral.clonarObjeto(state.detalhesPagamento);

      parcelas.forEach((p, index) => {
        parcelas[index].statusTituloFinanceiro = EStatusTituloFinanceiro.Pendente;
        parcelas[index].codigoTipoDocumentoFinanceiro = state.detalhePagamento.codigoTipoDocumentoFinanceiro;
        parcelas[index].descricaoTipoDocumentoFinanceiro = state.detalhePagamento.descricaoTipoDocumentoFinanceiro;
        parcelas[index].valorPagamento = state.detalhePagamento.valorPagamento;
        parcelas[index].valorTotal = state.detalhePagamento.valorPagamento;
        parcelas[index].codigoConta = state.detalhePagamento.codigoConta;
        parcelas[index].descricaoConta = state.detalhePagamento.descricaoConta;
        parcelas[index].codigoPlanoContaCategoria = state.detalhePagamento.codigoPlanoContaCategoria;
        parcelas[index].nomePlanoContaCategoria = state.detalhePagamento.nomePlanoContaCategoria;
        parcelas[index].observacoes = state.detalhePagamento.observacoes;
      });
      return parcelas;
    }

    function salvar() {
      if (validarCampos()) {
        state.detalhePagamento.valorTotal = state.detalhePagamento.valorPagamento;
        if (state.repetir) {
          const parcelas = obterParcelas();
          emit('salvar', parcelas);
        } else if (props.detalhesPagamento.length > 1) {
          const parcelas = obterParcelasAtualizadas();
          emit('salvar', parcelas);
        } else {
          state.detalhePagamento.alterado = true;
          emit('salvar', [state.detalhePagamento]);
        }
        modalBase.computedVisivel = false;
      }
    }

    function cancelar() {
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      limparTela();
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        if (props.detalhesPagamento.length > 0) {
          state.detalhePagamento = UtilitarioGeral.clonarObjeto(props.detalhesPagamento[0]);
          state.detalhesPagamento = UtilitarioGeral.clonarObjeto(props.detalhesPagamento);
        } else {
          state.detalhePagamento = UtilitarioGeral.clonarObjeto(props.detalhePagamento);
        }
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      ESazonalidade,
      UtilitarioGeral,
      atualizarUltimoVencimento,
      obterMensagemRepeticao,
      checkRepetir,
      salvar,
      cancelar,
    };
  },
});
