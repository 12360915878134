import { vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "text",
    maxlength: "18",
    class: "ant-input",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event))
  }, null, 512)), [
    [_vModelText, _ctx.computedValor],
    [_directive_maska, _ctx.UtilitarioMascara.formatoMascaraCPFeCNPJ]
  ])
}