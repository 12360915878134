import ApiERP from '@/core/conectores/ApiERP';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOPessoaCadastroRapido } from '@/models/DTO/Cadastros/Pessoas/IDTOPessoaCadastroRapido';
import { IDTOPessoaEmail } from '@/models/DTO/Cadastros/Pessoas/IDTOPessoaEmail';
import { IDTOPessoaMovimento } from '@/models/DTO/Cadastros/Pessoas/IDTOPessoaMovimento';
import { IPessoa } from '@/models/Entidades/Cadastros/Pessoas/IPessoa';
import { IPessoaContato } from '@/models/Entidades/Cadastros/Pessoas/IPessoaContato';
import { IPessoaEndereco } from '@/models/Entidades/Cadastros/Pessoas/IPessoaEndereco';
import { IArquivo } from '@/models/Entidades/MeuSistema/IArquivo';
import { ETipoRelacaoPessoa } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoRelacaoPessoa';
import { ETipoPessoa } from '@/models/Enumeradores/Compartilhados/ETipoPessoa';
import storeSistema from '@/store/storeSistema';

/**
 * Serviço de Pessoa
 * Fornece todas regras de negócios e lógicas relacionado a pessoas.
 */

class ServicoPessoa {
  endPoint = 'pessoas';

  apiERP = new ApiERP();

  public requisicaoSistema(): void {
    this.apiERP = new ApiERP(true);
  }

  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida, tipoRelacao?: ETipoRelacaoPessoa, apresentarCpfCnpj?:boolean, apresentarRelacoes?:boolean): Promise<IItemConsultaRapida[]> {
    let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);

    if (tipoRelacao !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `tipoRelacao=${tipoRelacao}`;
    }

    if (apresentarCpfCnpj !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `apresentarCpfCnpj=${apresentarCpfCnpj}`;
    }

    if (apresentarRelacoes !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `apresentarRelacoes=${apresentarRelacoes}`;
    }

    const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
    return result.data;
  }

  public async obterListaTipoPessoa(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/tipos`);
    return result.data;
  }

  public async obterListaTipoInscricoesEstaduais(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/tipos-inscricoes-estaduais`);
    return result.data;
  }

  public async obterListaTipoAtividade(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/tipos-atividades`);
    return result.data;
  }

  public async obterListaTipoRelacaoPessoa(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/tipos-relacoes`);
    return result.data;
  }

  public async obterListaEstadoCivil(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/estado-civis`);
    return result.data;
  }

  public async obterListaCategoriasCNH(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/categorias-cnh`);
    return result.data;
  }

  public async obterListaTipoEndereco(relacoesPessoa?: number[]): Promise<IItemGenerico[]> {
    let parametrosTipoEndereco = '';
    if (relacoesPessoa !== undefined && relacoesPessoa.length > 0) {
      relacoesPessoa.forEach((tipoRelacao) => {
        parametrosTipoEndereco += (parametrosTipoEndereco !== '' ? '&' : '?');
        parametrosTipoEndereco += `Relacoes=${tipoRelacao}`;
      });
    }

    const result: any = await this.apiERP.get(`${this.endPoint}/tipos-enderecos${parametrosTipoEndereco}`);
    return result.data;
  }

  public async obterListaTipoTelefone(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/tipos-telefone`);
    return result.data;
  }

  public async obterListaTipoPreferenciaContato(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/tipos-preferencia-contato`);
    return result.data;
  }

  public async obterListaProtestarTitulos(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/protestar-titulos`);
    return result.data;
  }

  public async cadastroRapido(objeto: IDTOPessoaCadastroRapido): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}/cadastro-rapido`, objeto);
    return result.data;
  }

  public async consultarMarcadores(marcardor: string): Promise<string[]> {
    let parametrosAdicionais = '';
    parametrosAdicionais += `?Marcador=${marcardor}`;

    const result: any = await this.apiERP.get(`${this.endPoint}/marcadores${parametrosAdicionais}`);
    return result.data;
  }

  public async validarEndereco(endereco: IPessoaEndereco): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}/validar/endereco`, endereco);
    return result.data;
  }

  public async validarContato(contato: IPessoaContato): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}/validar/contato`, contato);
    return result.data;
  }

  public async enviarFoto(arquivo: any): Promise<IRetornoArquivo> {
    const data = new FormData();
    data.append('arquivo', arquivo);
    const result: any = await this.apiERP.upload(`${this.endPoint}/foto/empresa/${storeSistema.getters.codigoEmpresaOperacao()}`, data);
    return result.data;
  }

  public async removerFoto(linkArquivo: string): Promise<IRetornoArquivo> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/foto?linkArquivo=${linkArquivo}`);
    return result.data;
  }

  public async gerarLinkAnexo(arquivo: IArquivo): Promise<IRetornoArquivo> {
    const result: any = await this.apiERP.post(`${this.endPoint}/anexos/gerar-link`, arquivo);
    return result.data;
  }

  public async enviarAnexos(arquivos: any[]): Promise<IRetornoArquivo[]> {
    const data = new FormData();
    for (let i = 0; i < arquivos.length; (i += 1)) {
      data.append('arquivos', arquivos[i]);
    }

    const result: any = await this.apiERP.upload(`${this.endPoint}/anexos/empresa/${storeSistema.getters.codigoEmpresaOperacao()}`, data);
    return result.data;
  }

  public async removerAnexo(codigoPessoaAnexo: number, codigoArquivo: number): Promise<IRetornoArquivo> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/anexos/${codigoPessoaAnexo}/arquivo/${codigoArquivo}`);
    return result.data;
  }

  public async obterNome(codigo: number, empresa: number): Promise<string> {
    const result: any = await this.apiERP.get(`${this.endPoint}/nome/${codigo}/empresa/${empresa}`);
    return result.data;
  }

  public async obterCodigoRelacao(codigo: number, tipoRelacao: ETipoRelacaoPessoa): Promise<number> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/codigo-relacao/relacao/${tipoRelacao}`);
    return result.data;
  }

  public async obterCodigoPessoaPelaRelacao(codigoRelacao: number, tipoRelacao: ETipoRelacaoPessoa): Promise<number> {
    const result: any = await this.apiERP.get(`${this.endPoint}/codigo-pessoa/${codigoRelacao}/relacao/${tipoRelacao}`);
    return result.data;
  }

  public async obterDadosMovimentacao(codigo: number, empresa: number): Promise<IDTOPessoaMovimento> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/movimentacao/empresa/${empresa}`);
    return result.data;
  }

  public async obterEmailsEContatosEmail(codigo: number, empresa: number): Promise<IDTOPessoaEmail> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/emails/empresa/${empresa}`);
    return result.data;
  }

  public async obterRelacoesCpfCnpj(codigo: number, cpfCnpj: string, tipoPessoa: ETipoPessoa, empresas: number[]): Promise<ETipoRelacaoPessoa[]> {
    let parametrosAdicionais = '';

    if (empresas !== undefined && empresas.length > 0) {
      empresas.forEach((codigoEmpresa) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Empresas=${codigoEmpresa}`;
      });
    }

    const result: any = await this.apiERP.get(`${this.endPoint}/relacoes-cpfcpnpj/${codigo}/cpfcnpj/${cpfCnpj}/tipo-pessoa/${tipoPessoa}${parametrosAdicionais}`);
    return result.data;
  }

  public async obterRelacoesNome(codigo: number, nome: string, tipoPessoa: ETipoPessoa, empresas: number[]): Promise<ETipoRelacaoPessoa[]> {
    let parametrosAdicionais = '';

    if (empresas !== undefined && empresas.length > 0) {
      empresas.forEach((codigoEmpresa) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Empresas=${codigoEmpresa}`;
      });
    }

    const result: any = await this.apiERP.get(`${this.endPoint}/relacoes-nome/${codigo}/nome/${nome}/tipo-pessoa/${tipoPessoa}${parametrosAdicionais}`);
    return result.data;
  }

  public async obterPeloNome(empresa: number, nome: string, tipoPessoa: ETipoPessoa, tipoRelacao: ETipoRelacaoPessoa): Promise<IPessoa> {
    const result: any = await this.apiERP.get(`${this.endPoint}/empresa/${empresa}/nome/${nome}/tipo-pessoa/${tipoPessoa}/tipo-relacao/${tipoRelacao}`);
    return result.data;
  }

  public async obterPeloCpfCnpj(empresa: number, cpfCnpj: string, tipoPessoa: ETipoPessoa, tipoRelacao: ETipoRelacaoPessoa): Promise<IPessoa> {
    const result: any = await this.apiERP.get(`${this.endPoint}/empresa/${empresa}/cpfcnpj/${cpfCnpj}/tipo-pessoa/${tipoPessoa}/tipo-relacao/${tipoRelacao}`);
    return result.data;
  }

  public async obterCodigoPessoaPeloCpfCnpjRelacao(empresa: number, cpfCnpj: string, tipoRelacao: ETipoRelacaoPessoa): Promise<number> {
    const result: any = await this.apiERP.get(`${this.endPoint}/empresa/${empresa}/cpfcnpj/${cpfCnpj}/tipo-relacao/${tipoRelacao}`);
    return result.data;
  }

  public obterDescricaoRelacao(tipo: ETipoRelacaoPessoa): string {
    switch (tipo) {
      case ETipoRelacaoPessoa.Cliente:
        return 'Cliente';
        break;
      case ETipoRelacaoPessoa.Colaborador:
        return 'Colaborador';
        break;
      case ETipoRelacaoPessoa.Fornecedor:
        return 'Fornecedor';
        break;
      case ETipoRelacaoPessoa.Representante:
        return 'Representante';
        break;
      case ETipoRelacaoPessoa.Transportadora:
        return 'Transportadora';
        break;
      default:
        return '';
        break;
    }
  }
}

export default ServicoPessoa;
