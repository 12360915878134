
import { defineComponent, computed, reactive } from 'vue';
import { IColunaKanban, ICardKanban } from '@/core/models/IKanban';
import ColunaKanban from './ColunaKanban.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IOrdenacaoRegistro } from '@/core/models/IOrdenacaoRegistro';
import Icone from '../Icone.vue';

export default defineComponent({
  name: 'Kanban',
  components: {
    ColunaKanban,
    Icone,
  },
  props: {
    titulo: {
      type: String,
      required: true,
      default: '',
    },
    switchListagem: {
      type: Boolean,
      default: false,
    },
    visualizacaoKanban: {
      type: Boolean,
      default: true,
    },
    colunas: {
      type: Array as () => IColunaKanban[],
      required: true,
    },
  },
  emits: ['update:colunas', 'update:visualizacaoKanban', 'selecaoCardAlterada', 'colunaCardAlterada', 'identificadoresSelecionados', 'qtdSelecaoAlterada', 'ordemCardsAlterada', 'clickNovo', 'clickVisualizar', 'clickSubMenu'],
  setup(props, { emit }) {
    const state = reactive({
    });

    const computedColunas = computed({
      get: () => props.colunas,
      set: (colunas: IColunaKanban[]) => {
        emit('update:colunas', colunas);
      },
    });

    const computedVisualizacaoKanban = computed({
      get: () => props.visualizacaoKanban,
      set: (valor: boolean) => {
        emit('update:visualizacaoKanban', valor);
      },
    });

    function selecaoCardAlterada(identificadorCard: string) {
      emit('selecaoCardAlterada', identificadorCard);
    }

    function identificadoresSelecionados(identificadoresSelecionados: string[]) {
      emit('identificadoresSelecionados', identificadoresSelecionados);
    }

    function qtdSelecaoAlterada(identificadorCard: string) {
      emit('qtdSelecaoAlterada', identificadorCard);
    }

    function colunaCardAlterada(card:ICardKanban, identificadorNovaColuna: string) {
      emit('colunaCardAlterada', card, identificadorNovaColuna);
    }

    function clickNovo(identificador: string) {
      emit('clickNovo', identificador);
    }

    function clickVisualizar(identificadorCard: string) {
      emit('clickVisualizar', identificadorCard);
    }

    function clickSubMenu(identificadorCard: string) {
      emit('clickSubMenu', identificadorCard);
    }

    function obterOrdenacaoRegistros(cards: ICardKanban[]) {
      const ordenacaoRegistros = [] as IOrdenacaoRegistro[];

      cards.forEach((c) => {
        const ordenacao = {} as IOrdenacaoRegistro;
        ordenacao.codigoRegistro = Number(c.identificador);
        ordenacao.ordem = c.ordem;
        ordenacaoRegistros.push(ordenacao);
      });
      return ordenacaoRegistros;
    }

    function ordemCardsAlterada(identificadorColuna:string) {
      const indexColuna = computedColunas.value.findIndex((c) => c.identificador === identificadorColuna);
      const coluna = UtilitarioGeral.instanciaObjetoLocal(computedColunas.value[indexColuna]);
      if (UtilitarioGeral.objetoValido(coluna)) {
        if (UtilitarioGeral.validaLista(coluna.cards)) {
          for (let index = 0; index < coluna.cards.length; index += 1) {
            coluna.cards[index].ordem = (index + 1);
            coluna.cards[index].identificadorColuna = identificadorColuna;
          }
          computedColunas.value[indexColuna] = coluna;

          emit('ordemCardsAlterada', obterOrdenacaoRegistros(coluna.cards));
        }
      }
    }

    return {
      props,
      state,
      computedColunas,
      computedVisualizacaoKanban,
      selecaoCardAlterada,
      colunaCardAlterada,
      qtdSelecaoAlterada,
      clickNovo,
      clickVisualizar,
      clickSubMenu,
      ordemCardsAlterada,
      identificadoresSelecionados,
    };
  },
});

