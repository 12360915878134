import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_7 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_8 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selecionar_tipo_inscricao_estadual = _resolveComponent("selecionar-tipo-inscricao-estadual")!
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_a_form_item, {
          label: "Tipo IE",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_selecionar_tipo_inscricao_estadual, {
              codigoSelecionado: _ctx.computedInscricoes.tipoIe,
              "onUpdate:codigoSelecionado": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedInscricoes.tipoIe) = $event)),
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.verificaPreenchimentoIE($event)))
            }, null, 8, ["codigoSelecionado"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_form_item, {
          label: "Inscrição Estadual",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              maxlength: "14",
              class: "ant-input",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedInscricoes.inscricaoEstadual) = $event)),
              disabled: (_ctx.computedInscricoes.tipoIe !== _ctx.ETipoInscricaoEstadual.ContribuinteICMS)
            }, null, 8, _hoisted_4), [
              [_vModelText, _ctx.computedInscricoes.inscricaoEstadual]
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_a_form_item, {
          label: "Inscrição Municipal",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              maxlength: "15",
              class: "ant-input",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedInscricoes.inscricaoMunicipal) = $event))
            }, null, 512), [
              [_vModelText, _ctx.computedInscricoes.inscricaoMunicipal]
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_a_form_item, {
          label: "Inscrição SUFRAMA",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              maxlength: "9",
              class: "ant-input",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedInscricoes.inscricaoSuframa) = $event))
            }, null, 512), [
              [_vModelText, _ctx.computedInscricoes.inscricaoSuframa]
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_a_form_item, {
          label: "TARE",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              maxlength: "15",
              class: "ant-input",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedInscricoes.inscricaoTare) = $event))
            }, null, 512), [
              [_vModelText, _ctx.computedInscricoes.inscricaoTare]
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_a_form_item, {
          label: "ANTT",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              maxlength: "20",
              class: "ant-input",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedInscricoes.inscricaoAntt) = $event))
            }, null, 512), [
              [_vModelText, _ctx.computedInscricoes.inscricaoAntt]
            ])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}