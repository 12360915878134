
import {
  defineComponent, onBeforeMount, reactive, watch,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { useTelaBase } from '@/core/composables/TelaBase';
import SelecionarDataInicialFinal from '@/core/components/Tela/SelecionarDataInicialFinal.vue';
import SelecionarFiltroPeriodoMovimentoComercial from '@/components/Compartilhados/SelecionarFiltroPeriodoMovimentoComercial.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import Icone from '@/core/components/Icone.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import storeSistema from '@/store/storeSistema';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { ICardKanban, ICardKanbanDetalhe, IColunaKanban } from '@/core/models/IKanban';
import Kanban from '@/core/components/Kanban/Kanban.vue';
import { IOrdenacaoRegistro } from '@/core/models/IOrdenacaoRegistro';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IPagination } from '@/core/models/AntDesign/IPagination';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import NotaFiscalServicoListagem from '@/components/Fiscal/NotasFiscaisServicos/NotaFiscalServicoListagem.vue';
import NotaFiscalServicoModal from './NotaFiscalServicoModal.vue';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import Card from '@/core/components/Tela/Card.vue';
import { EFiltroPeriodoMovimentoComercial } from '@/models/Enumeradores/Compartilhados/EFiltroPeriodoMovimentoComercial';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import BuscaGeral from '@/core/components/BuscaAvancada/BuscaGeral.vue';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import { IRetornoNotaFiscalServico } from '@/models/Retornos/Fiscal/IRetornoNotaFiscalServico';
import { EStatusNotaFiscalServico } from '@/models/Enumeradores/Fiscal/NotasFiscaisServicos/EStatusNotaFiscalServico';
import { IDTONotaFiscalServico } from '@/models/DTO/Fiscal/NotasFiscaisServico/IDTONotaFiscalServico';
import ServicoNotaFiscalServico from '@/servicos/Fiscal/ServicoNotaFiscalServico';
import { IParametrosConsultaNotaFiscalServico } from '@/models/ParametrosRequisicao/Fiscal/IParametrosConsultaNotaFiscalServico';
import EnviarEmailPessoaModal from '@/components/Cadastros/Pessoas/EnviarEmailPessoaModal.vue';
import { IRetornoComunicacaoDFe } from '@/core/models/IRetornoComunicacaoDFe';

export default defineComponent({
  name: 'NotaFiscalServicoIndex',
  components: {
    Kanban,
    NotaFiscalServicoModal,
    NotaFiscalServicoListagem,
    SelecionarDataInicialFinal,
    SelecionarFiltroPeriodoMovimentoComercial,
    Icone,
    Card,
    TituloPadrao,
    BuscaGeral,
    EnviarEmailPessoaModal,
    PreviewPdf,
  },
  setup(props) {
    const servicoNotaFiscalServico = new ServicoNotaFiscalServico();
    let debounce = 0;
    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis,
      defineEmpresasSelecionadasComPermissao, filtrarPermissaoDadosUsuarioMultiEmpresas,
      adicionarAtalho, removerAtalho, apresentarMensagemSucesso, apresentarMensagemAlerta, apresentarMensagemErro,
    } = useTelaBase();
    const route = useRoute();
    const state = reactive({
      identificadorRecurso: 'NOTAS_FISCAIS_SERVICO',
      identificadorPermissao: 'AUT_NOTAS_FISCAIS_SERVICO',
      empresaSelecionada: 0,
      dataAtual: '',
      filtroPeriodo: 4,
      dataInicialSelecionada: '',
      dataFinalSelecionada: '',
      buscaRapida: '',
      colunas: [] as IColunaKanban[],
      filtros: [] as IFiltroGenerico[],
      selecionados: [] as number[],
      paginacao: {} as IPagination,
      cardSelecionado: '',
      notasServicos: [] as IDTONotaFiscalServico[],
      buscaAvancada: {} as IBuscaAvancada,
      exibirLancamento: false,
      operacaoLancamento: {} as ITelaOperacao,
      qtdSelecionados: 0,
      qtdSelecionadosAutorizadas: 0,
      qtdSelecionadosConcluir: 0,
      qtdSelecionadosRetomar: 0,
      qtdSelecionadosCancelar: 0,
      qtdSelecionadosExcluir: 0,
      visualizarKanban: true,
      atualizandoCards: false,
      arquivosPdf: [] as IArquivoPdf[],
      apresentarEnvioEmail: false,
      codigoPessoaEmail: 0,
      notaServicoEnvioEmail: {} as IDTONotaFiscalServico,
      sincronizarProcessamento: false,
    });

    function atualizarQtdSelecionados() {
      state.qtdSelecionados = 0;
      state.qtdSelecionadosAutorizadas = 0;
      state.qtdSelecionadosConcluir = 0;
      state.qtdSelecionadosCancelar = 0;
      state.qtdSelecionadosExcluir = 0;
      state.qtdSelecionadosRetomar = 0;
      state.colunas.forEach((c) => {
        if (c.qtdSelecionados !== undefined) {
          state.qtdSelecionados += c.qtdSelecionados;
          if (Number(c.identificador) === EStatusNotaFiscalServico.Autorizada) {
            state.qtdSelecionadosAutorizadas += c.qtdSelecionados;
          }
        }
      });
    }

    function atualizarSelecionadosKanban() {
      state.colunas.forEach((c, index) => {
        state.colunas[index].qtdSelecionados = 0;
      });

      state.notasServicos.forEach((n) => {
        const indexColuna = state.colunas.findIndex((c) => c.identificador === String(n.status));
        const indexCard = state.colunas[indexColuna].cards.findIndex((c) => c.identificador === String(n.codigo));
        const indexSelecionados = state.selecionados.findIndex((s) => s === n.codigo);
        if (indexSelecionados >= 0) state.colunas[indexColuna].qtdSelecionados += 1;
        state.colunas[indexColuna].cards[indexCard].selecionado = indexSelecionados >= 0;
      });
      atualizarQtdSelecionados();
    }

    function atualizarSelecionados(identificador: string) {
      const indexSelecionados = state.selecionados.findIndex((s) => s === Number(identificador));
      if (indexSelecionados < 0) {
        state.selecionados.push(Number(identificador));
      } else {
        state.selecionados.splice(indexSelecionados, 1);
      }
      atualizarQtdSelecionados();
    }

    function atualizarSelecionadosEmMassa(identificadoresSelecionados: string[]) {
      identificadoresSelecionados.forEach((identificador) => {
        const indexSelecionados = state.selecionados.findIndex((s) => s === Number(identificador));
        if (indexSelecionados < 0) {
          state.selecionados.push(Number(identificador));
        } else {
          state.selecionados.splice(indexSelecionados, 1);
        }
      });

      atualizarQtdSelecionados();
    }

    function desmarcarSelecionado(codigo: number, status: number, statusNovo: number) {
      const indexSelecionados = state.selecionados.findIndex((s) => s === codigo);
      if (indexSelecionados >= 0) {
        let indexColuna = state.colunas.findIndex((c) => c.identificador === String(status));
        let indexCard = state.colunas[indexColuna].cards.findIndex((c) => c.identificador === String(codigo));
        if (indexCard === -1) {
          indexColuna = state.colunas.findIndex((c) => c.identificador === String(statusNovo));
          indexCard = state.colunas[indexColuna].cards.findIndex((c) => c.identificador === String(codigo));
        }
        if (state.colunas[indexColuna].qtdSelecionados > 0) state.colunas[indexColuna].qtdSelecionados -= 1;
        state.colunas[indexColuna].cards[indexCard].selecionado = false;
        state.selecionados.splice(indexSelecionados, 1);
      }
      atualizarQtdSelecionados();
    }

    function preencherColunas() {
      state.colunas = [] as IColunaKanban[];
      state.colunas[0] = {} as IColunaKanban;
      state.colunas[0].identificador = '1';
      state.colunas[0].titulo = 'Pendente';
      state.colunas[0].cor = '#969696';
      state.colunas[0].textoTotalizador = 'Total';
      state.colunas[0].cards = [] as ICardKanban[];

      state.colunas[1] = {} as IColunaKanban;
      state.colunas[1].identificador = '4';
      state.colunas[1].titulo = 'Em Processamento';
      state.colunas[1].cor = '#d6c720';
      state.colunas[1].textoTotalizador = 'Total';
      state.colunas[1].cards = [] as ICardKanban[];

      state.colunas[2] = {} as IColunaKanban;
      state.colunas[2].identificador = '2';
      state.colunas[2].titulo = 'Autorizadas';
      state.colunas[2].cor = '#1843b8';
      state.colunas[2].textoTotalizador = 'Total';
      state.colunas[2].cards = [] as ICardKanban[];

      state.colunas[3] = {} as IColunaKanban;
      state.colunas[3].identificador = '3';
      state.colunas[3].titulo = 'Canceladas';
      state.colunas[3].cor = '#cf2751';
      state.colunas[3].textoTotalizador = 'Total';
      state.colunas[3].cards = [] as ICardKanban[];
    }

    function limparCards() {
      state.colunas.forEach((c, index) => {
        state.colunas[index].qtdSelecionados = 0;
        state.colunas[index].cards = [] as ICardKanban[];
      });
    }

    function limparNotaFiscalServicoEnvioEmail() {
      state.codigoPessoaEmail = 0;
      state.notaServicoEnvioEmail = {} as IDTONotaFiscalServico;
      state.notaServicoEnvioEmail.codigo = 0;
      state.notaServicoEnvioEmail.codigoTomador = 0;
      state.notaServicoEnvioEmail.numeroNfse = 0;
    }

    function criarCard(indexColuna: number, notaFiscalServico: IDTONotaFiscalServico) {
      const card = {} as ICardKanban;
      const indexSelecionados = state.selecionados.findIndex((s) => s === notaFiscalServico.codigo);

      card.identificadorColuna = state.colunas[indexColuna].identificador;
      card.identificador = String(notaFiscalServico.codigo);
      let indexDetalhe = 0;
      if ((notaFiscalServico.status === EStatusNotaFiscalServico.Autorizada || notaFiscalServico.status === EStatusNotaFiscalServico.Cancelada) && notaFiscalServico.numeroNfse > 0) {
        card.titulo = `Nº NFS-e: ${notaFiscalServico.numeroNfse}`;
        card.ordem = notaFiscalServico.ordem;
        card.selecionado = indexSelecionados >= 0;
        card.detalhes = [] as ICardKanbanDetalhe[];
        card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
        card.detalhes[indexDetalhe].titulo = 'Nº RPS:';
        card.detalhes[indexDetalhe].detalhe = notaFiscalServico.numeroRps.toString();
        indexDetalhe += 1;
      } else {
        card.titulo = `Nº RPS: ${notaFiscalServico.numeroRps}`;
        card.ordem = notaFiscalServico.ordem;
        card.selecionado = indexSelecionados >= 0;
        card.detalhes = [] as ICardKanbanDetalhe[];
        if (notaFiscalServico.numeroNfse > 0) {
          card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
          card.detalhes[indexDetalhe].titulo = 'NFS-e:';
          card.detalhes[indexDetalhe].detalhe = notaFiscalServico.numeroNfse.toString();
          indexDetalhe += 1;
        }
      }

      card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
      card.detalhes[indexDetalhe].titulo = 'Data de Emissão: ';
      card.detalhes[indexDetalhe].detalhe = UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(notaFiscalServico.dataEmissaoRps);
      indexDetalhe += 1;

      card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
      card.detalhes[indexDetalhe].titulo = 'Tomador: ';
      card.detalhes[indexDetalhe].detalhe = notaFiscalServico.nomeRazaoSocialTomador.length > 29 ? notaFiscalServico.nomeRazaoSocialTomador.substring(0, 30) : notaFiscalServico.nomeRazaoSocialTomador;

      indexDetalhe += 1;
      card.detalhes[indexDetalhe] = {} as ICardKanbanDetalhe;
      card.detalhes[indexDetalhe].titulo = 'Total R$: ';
      card.detalhes[indexDetalhe].detalhe = UtilitarioMascara.mascararValor(notaFiscalServico.totalNotaFiscal, 2);
      card.valor = notaFiscalServico.totalNotaFiscal;

      state.colunas[indexColuna].cards.push(card);
      if (indexSelecionados >= 0) state.colunas[indexColuna].qtdSelecionados += 1;
    }

    function prencheDatasPeriodo() {
      switch (state.filtroPeriodo) {
        case EFiltroPeriodoMovimentoComercial.Hoje:
          state.dataInicialSelecionada = state.dataAtual;
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ontem:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 1);
          state.dataFinalSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 1);
          break;

        case EFiltroPeriodoMovimentoComercial.SemanaAtual:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 7);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.MesAtual:
          state.dataInicialSelecionada = UtilitarioData.obterPrimeiroDiaMes(state.dataAtual);
          state.dataFinalSelecionada = UtilitarioData.obterUltimoDiaMes(state.dataAtual);
          break;

        case EFiltroPeriodoMovimentoComercial.AnoAtual:
          state.dataInicialSelecionada = UtilitarioData.obterPrimeiroDiaAno(state.dataAtual);
          state.dataFinalSelecionada = UtilitarioData.obterUltimoDiaAno(state.dataAtual);
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos15Dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 15);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos30Dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 30);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos60Dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 60);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        case EFiltroPeriodoMovimentoComercial.Ultimos90dias:
          state.dataInicialSelecionada = UtilitarioData.diminuirDiaNaData(state.dataAtual, 90);
          state.dataFinalSelecionada = state.dataAtual;
          break;

        default:
      }
    }

    async function buscarNotasServicos(ordenacao: IOrdenacao[] = []) {
      state.notasServicos = [];
      limparCards();
      state.filtros = [] as IFiltroGenerico[];
      state.buscaAvancada.filtrosAdicionados.forEach((item) => {
        state.filtros.push(item.filtro);
      });

      prencheDatasPeriodo();
      const parametrosConsulta = {} as IParametrosConsultaNotaFiscalServico;
      parametrosConsulta.empresas = [state.empresaSelecionada];
      parametrosConsulta.ordenacao = Array<string>();
      parametrosConsulta.periodo = state.filtroPeriodo;
      parametrosConsulta.dataInicial = state.dataInicialSelecionada;
      parametrosConsulta.dataFinal = state.dataFinalSelecionada;
      parametrosConsulta.valorBuscaRapida = state.buscaRapida;
      if (UtilitarioGeral.validaLista(ordenacao) && !state.visualizarKanban) {
        ordenacao.forEach((item) => {
          parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
        });
      } else {
        parametrosConsulta.ordenacao.push('Status', 'Ordem');
      }

      parametrosConsulta.qtdeRegistrosPagina = 1000;

      telaBase.carregando = true;
      const listaPaginada = await servicoNotaFiscalServico.buscaAvancada(parametrosConsulta, state.filtros);
      state.notasServicos = listaPaginada.dados;
      state.notasServicos.forEach((n) => {
        criarCard(state.colunas.findIndex((c) => c.identificador === String(n.status)), n);
      });
      state.sincronizarProcessamento = state.notasServicos.some((c) => c.status === EStatusNotaFiscalServico.EmProcessamento);
      telaBase.carregando = false;
    }

    async function atualizarOrdemCards(ordenacaoRegistros: IOrdenacaoRegistro[]) {
      await servicoNotaFiscalServico.atualizarOrdenacao(ordenacaoRegistros, state.empresaSelecionada);
    }

    function recriarColuna(identificador: string) {
      const index = state.colunas.findIndex((c) => c.identificador === identificador);

      state.colunas[index].cards = [] as ICardKanban[];
      state.colunas[index].qtdSelecionados = 0;
      state.colunas[index].textoTotalizador = 'Total';
      state.notasServicos.forEach((n) => {
        if (n.status === Number(identificador)) {
          criarCard(index, n);
        }
      });
      atualizarQtdSelecionados();
    }

    function obterTituloColuna(identificador: string) {
      const index = state.colunas.findIndex((c) => c.identificador === identificador);
      return state.colunas[index].titulo;
    }

    async function atualizarSelecaoEmpresas() {
      telaBase.empresasSelecionadas = [] as number[];
      telaBase.empresasSelecionadas.push(state.empresaSelecionada);
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
      await buscarNotasServicos();
    }

    function selecionarCard(selecionado: string) {
      state.cardSelecionado = selecionado;
    }

    function incluir() {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = 0;
      state.operacaoLancamento.empresaSelecionada = state.empresaSelecionada;
      state.exibirLancamento = true;
    }

    function editar(codigo: number) {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Alterar;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = codigo;
      state.operacaoLancamento.empresaSelecionada = state.empresaSelecionada;
      state.exibirLancamento = true;
    }

    function clickEditar() {
      editar(Number(state.cardSelecionado));
    }

    async function enviarEmailNFse(emails: string []) {
      if (UtilitarioGeral.validaLista(emails)) {
        const retorno:IRetornoComunicacaoDFe = await servicoNotaFiscalServico.enviarEmail(state.notaServicoEnvioEmail.codigo, emails);
        if (retorno.sucesso) {
          apresentarMensagemSucesso(retorno.mensagem);
        } else {
          apresentarMensagemAlerta(retorno.mensagem);
        }
      } else {
        apresentarMensagemAlerta('Nenhum e-mail informado para o envio.');
      }
      state.apresentarEnvioEmail = false;
      limparNotaFiscalServicoEnvioEmail();
    }
    async function alterarEtapa(notaFiscalServico: IDTONotaFiscalServico, novaEtapa: string) {
      let retorno = {} as IRetornoNotaFiscalServico;
      const statusAntigo = String(notaFiscalServico.status);
      const index = state.notasServicos.findIndex((n) => n.codigo === notaFiscalServico.codigo);
      desmarcarSelecionado(state.notasServicos[index].codigo, state.notasServicos[index].status, Number(novaEtapa));
      retorno = await servicoNotaFiscalServico.alterarEtapa(notaFiscalServico.codigo, notaFiscalServico.codigoEmpresa, Number(novaEtapa));
      if (retorno.status === 1) {
        state.notasServicos[index].status = Number(novaEtapa);
        if (UtilitarioGeral.valorValido(retorno.linkDocumentoFiscal)) {
          state.arquivosPdf = [{ nome: '', link: retorno.linkDocumentoFiscal } as IArquivoPdf];
        }
      } else if (retorno.status === 2) {
        apresentarMensagemAlerta(retorno.mensagem);
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
      if (state.visualizarKanban) {
        recriarColuna(novaEtapa);
        recriarColuna(statusAntigo);
      } else {
        buscarNotasServicos();
      }
    }

    async function alterarEtapaVarios(notasServicos: IDTONotaFiscalServico[], proximaEtapa: boolean, cancelamento: boolean) {
      let retorno = {} as IRetornoRequisicao;
      const colunasAlteradas = [] as string[];
      const codigosNotasServicos = [] as number[];

      notasServicos.forEach((notaFiscalServico) => {
        let novaEtapa = 0;

        if (cancelamento) {
          novaEtapa = 3;
        } else if (proximaEtapa) {
          novaEtapa = notaFiscalServico.status + 1;
        } else {
          novaEtapa = notaFiscalServico.status - 1;
        }

        if (!colunasAlteradas.some((c) => c === String(notaFiscalServico.status))) {
          colunasAlteradas.push(String(notaFiscalServico.status));
        }
        if (!colunasAlteradas.some((c) => c === String(novaEtapa))) {
          colunasAlteradas.push(String(novaEtapa));
        }
        desmarcarSelecionado(notaFiscalServico.codigo, notaFiscalServico.status, Number(novaEtapa));
        codigosNotasServicos.push(notaFiscalServico.codigo);
      });
      retorno = await servicoNotaFiscalServico.alterarEtapaVarios(state.empresaSelecionada, proximaEtapa, cancelamento, codigosNotasServicos);
      if (retorno.status === 1) {
        state.notasServicos.forEach((d, index) => {
          if (cancelamento) {
            state.notasServicos[index].status = 3;
          } else if (proximaEtapa) {
            state.notasServicos[index].status += 1;
          } else {
            state.notasServicos[index].status -= 1;
          }
        });
      } else if (retorno.status === 2) {
        apresentarMensagemAlerta(retorno.mensagem);
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
      if (state.visualizarKanban) {
        colunasAlteradas.forEach((c) => {
          recriarColuna(c);
        });
      } else {
        buscarNotasServicos();
      }
    }

    function mensagemVerificacaoPadrao(notaFiscalServico: IDTONotaFiscalServico): string {
      if (notaFiscalServico.numeroNfse > 0) {
        return `RPS: ${notaFiscalServico.numeroRps} NFS-e: ${notaFiscalServico.numeroNfse} emitida no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(notaFiscalServico.dataEmissaoNfse)}.`;
      }
      return `RPS: ${notaFiscalServico.numeroRps} criada no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(notaFiscalServico.dataEmissaoRps)}.`;
    }

    async function acaoDesabilitada(card: ICardKanban, identificadorNovaColuna: string) {
      state.cardSelecionado = card.identificador;
      const indexNotaFiscalServico = state.notasServicos.findIndex((n) => n.codigo === Number(state.cardSelecionado));
      const notaFiscalServico = state.notasServicos[indexNotaFiscalServico];
      apresentarMensagemAlerta('As etapas serão atualizadas mediante atualização o retorno da prefeitura.');
      recriarColuna(identificadorNovaColuna);
      recriarColuna(String(notaFiscalServico.status));
    }

    async function moverCard(card: ICardKanban, identificadorNovaColuna: string) {
      state.cardSelecionado = card.identificador;
      const indexNotaFiscalServico = state.notasServicos.findIndex((n) => n.codigo === Number(state.cardSelecionado));
      const notaFiscalServico = state.notasServicos[indexNotaFiscalServico];
      if (notaFiscalServico.status === EStatusNotaFiscalServico.Cancelada) {
        apresentarMensagemAlerta('A NFS-e informada está cancelada.');
        recriarColuna(identificadorNovaColuna);
        recriarColuna(String(notaFiscalServico.status));
        return;
      }
      Modal.confirm({
        title: `Realmente deseja passar da etapa ${obterTituloColuna(String(notaFiscalServico.status))} para a etapa ${obterTituloColuna(identificadorNovaColuna)}?`,
        content: mensagemVerificacaoPadrao(notaFiscalServico),
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          await alterarEtapa(notaFiscalServico, identificadorNovaColuna);
        },
        onCancel: () => {
          recriarColuna(identificadorNovaColuna);
          recriarColuna(String(notaFiscalServico.status));
        },
      });
    }

    async function concluirEtapa() {
      let notaFiscalServico = {} as IDTONotaFiscalServico;
      let titulo = '';
      let mensagem = '';

      if (state.qtdSelecionadosConcluir === 0) {
        apresentarMensagemAlerta('Nenhuma Nota fiscal de serviço selecionada para conclusão de etapa.');
        return;
      } if (state.qtdSelecionadosConcluir === 1) {
        state.selecionados.forEach((s) => {
          const index = state.notasServicos.findIndex((n) => n.codigo === s);
          if (state.notasServicos[index].status < 3) {
            notaFiscalServico = state.notasServicos[index];
          }
        });
        titulo = `Realmente deseja passar da etapa ${obterTituloColuna(String(notaFiscalServico.status))} para a etapa ${obterTituloColuna(String(notaFiscalServico.status + 1))}?`;
        mensagem = `Nº RPS: ${notaFiscalServico.numeroRps} Nº NFS-e ${notaFiscalServico.numeroNfse} emitida no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(notaFiscalServico.dataEmissaoRps)}.`;
      } else {
        titulo = `Realmente deseja alterar a etapa das ${state.qtdSelecionadosConcluir} notas fiscais selecionadas?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          if (state.qtdSelecionadosConcluir === 1) {
            await alterarEtapa(notaFiscalServico, String(notaFiscalServico.status + 1));
          } else {
            const notasServicos = [] as IDTONotaFiscalServico[];
            state.selecionados.forEach((s) => {
              const indexNotaFiscalServico = state.notasServicos.findIndex((r) => r.codigo === Number(s));
              if (state.notasServicos[indexNotaFiscalServico].status < 3) {
                notasServicos.push(state.notasServicos[indexNotaFiscalServico]);
              }
            });
            await alterarEtapaVarios(notasServicos, true, false);
          }
        },
      });
    }

    async function retomarEtapa() {
      let notaFiscalServico = {} as IDTONotaFiscalServico;
      let titulo = '';
      let mensagem = '';

      if (state.qtdSelecionadosRetomar === 0) {
        apresentarMensagemAlerta('Nenhuma nota fiscal de serviço selecionada para retomada de etapa.');
        return;
      } if (state.qtdSelecionadosRetomar === 1) {
        state.selecionados.forEach((s) => {
          const index = state.notasServicos.findIndex((r) => r.codigo === s);
          if (state.notasServicos[index].status > 1 && state.notasServicos[index].status < 3) {
            notaFiscalServico = state.notasServicos[index];
          }
        });
        titulo = `Realmente deseja passar da etapa ${obterTituloColuna(String(notaFiscalServico.status))} para a etapa ${obterTituloColuna(String(notaFiscalServico.status - 1))}?`;
        mensagem = mensagemVerificacaoPadrao(notaFiscalServico);
      } else {
        titulo = `Realmente deseja alterar a etapa das ${state.qtdSelecionadosConcluir} notas fiscais de serviços selecionadas?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          if (state.qtdSelecionadosRetomar === 1) {
            await alterarEtapa(notaFiscalServico, String(notaFiscalServico.status - 1));
          } else {
            const notasServicos = [] as IDTONotaFiscalServico[];
            state.selecionados.forEach((s) => {
              const indexNotaFiscalServico = state.notasServicos.findIndex((r) => r.codigo === Number(s));
              if (state.notasServicos[indexNotaFiscalServico].status > 1 && state.notasServicos[indexNotaFiscalServico].status < 3) {
                notasServicos.push(state.notasServicos[indexNotaFiscalServico]);
              }
            });
            await alterarEtapaVarios(notasServicos, false, false);
          }
        },
      });
    }

    async function cancelar() {
      let notaFiscalServico = {} as IDTONotaFiscalServico;
      let titulo = '';
      let mensagem = '';

      if (state.qtdSelecionadosCancelar === 0) {
        apresentarMensagemAlerta('Nenhuma nota fiscal de serviço selecionada para o cancelamento.');
        return;
      } if (state.qtdSelecionadosCancelar === 1) {
        state.selecionados.forEach((s) => {
          const index = state.notasServicos.findIndex((n) => n.codigo === s);
          if (state.notasServicos[index].status < 3) {
            notaFiscalServico = state.notasServicos[index];
          }
        });
        titulo = 'Realmente deseja cancelar a nota fiscal de serviço?';
        mensagem = mensagemVerificacaoPadrao(notaFiscalServico);
      } else {
        titulo = `Realmente deseja cancelar as ${state.qtdSelecionadosConcluir} notas fiscais de serviço selecionadas?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          if (state.qtdSelecionadosCancelar === 1) {
            await alterarEtapa(notaFiscalServico, '3');
          } else {
            const notasServicos = [] as IDTONotaFiscalServico[];
            state.selecionados.forEach((s) => {
              const indexNotaFiscalServico = state.notasServicos.findIndex((n) => n.codigo === Number(s));
              if (state.notasServicos[indexNotaFiscalServico].status < 3) {
                notasServicos.push(state.notasServicos[indexNotaFiscalServico]);
              }
            });
            await alterarEtapaVarios(notasServicos, false, true);
          }
        },
      });
    }

    async function excluirnotasServicos(notaFiscalServico: IDTONotaFiscalServico[]) {
      const colunasAlteradas = [] as string[];
      const codigosnotasServicos = [] as number[];

      notaFiscalServico.forEach((notaFiscalServico) => {
        desmarcarSelecionado(notaFiscalServico.codigo, notaFiscalServico.status, notaFiscalServico.status);
        if (!colunasAlteradas.some((c) => c === String(notaFiscalServico.status))) {
          colunasAlteradas.push(String(notaFiscalServico.status));
        }
        codigosnotasServicos.push(notaFiscalServico.codigo);
      });

      const retorno = await servicoNotaFiscalServico.excluirVarios(state.empresaSelecionada, codigosnotasServicos);
      if (retorno.status === 1) {
        notaFiscalServico.forEach((notaFiscalServico) => {
          const indexNotaFiscalServico = state.notasServicos.findIndex((n) => n.codigo === notaFiscalServico.codigo);
          state.notasServicos.splice(indexNotaFiscalServico, 1);
        });
        colunasAlteradas.forEach((c) => {
          recriarColuna(c);
        });
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
    }

    async function excluir() {
      const notasServicos = [] as IDTONotaFiscalServico[];
      let notaFiscalServico = {} as IDTONotaFiscalServico;
      let titulo = '';
      let mensagem = '';

      if (state.qtdSelecionadosExcluir === 0) {
        apresentarMensagemAlerta('Nenhuma nota fiscal de serviço selecionada para exclusão.');
        return;
      } if (state.qtdSelecionadosExcluir === 1) {
        state.selecionados.forEach((s) => {
          const index = state.notasServicos.findIndex((n) => n.codigo === s);
          if (state.notasServicos[index].status === 1) {
            notaFiscalServico = state.notasServicos[index];
          }
        });
        titulo = 'Realmente deseja excluir a nota fiscal de serviço?';
        mensagem = `RPS ${notaFiscalServico.numeroRps} Nota ${notaFiscalServico.numeroNfse} emitida no dia ${UtilitarioData.aplicaFormatoDataHoraMinutoSegundo(notaFiscalServico.dataEmissaoRps)}.`;
      } else {
        titulo = `Realmente deseja excluir as ${state.qtdSelecionadosExcluir} notas fiscais de serviço selecionadas?`;
        mensagem = '';
      }

      Modal.confirm({
        title: titulo,
        content: mensagem,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          if (state.qtdSelecionadosExcluir === 1) {
            notasServicos.push(notaFiscalServico);
            await excluirnotasServicos(notasServicos);
          } else {
            state.selecionados.forEach((s) => {
              const indexNotaFiscalServico = state.notasServicos.findIndex((r) => r.codigo === Number(s));
              if (state.notasServicos[indexNotaFiscalServico].status === 1) {
                notasServicos.push(state.notasServicos[indexNotaFiscalServico]);
              }
            });
            await excluirnotasServicos(notasServicos);
          }
        },
      });
    }

    async function imprimirDetalhado(codgo: number) {
      telaBase.carregando = true;
      const retornoRelatorio = await servicoNotaFiscalServico.imprimir(codgo, state.empresaSelecionada, ETipoArquivo.PDF);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function imprimirListagem(codigos: number[]) {
      const parametrosConsulta = {} as IParametrosConsultaNotaFiscalServico;
      parametrosConsulta.empresas = [state.empresaSelecionada];
      parametrosConsulta.numeroPagina = 1;
      parametrosConsulta.qtdeRegistrosPagina = 0;
      parametrosConsulta.qtdeRegistrosTotal = 0;
      parametrosConsulta.periodo = state.filtroPeriodo;
      parametrosConsulta.dataInicial = state.dataInicialSelecionada;
      parametrosConsulta.dataFinal = state.dataFinalSelecionada;
      parametrosConsulta.ordenacao = Array<string>();
      parametrosConsulta.ordenacao.push('Status', 'Ordem');

      telaBase.carregando = true;
      const retornoRelatorio = await servicoNotaFiscalServico.relatorioPadrao(ETipoArquivo.PDF, parametrosConsulta, state.filtros, codigos);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function imprimir() {
      if (state.selecionados.length === 0) {
        apresentarMensagemAlerta('Nenhuma nota fiscal de serviço selecionada para impressão!');
      } else if (state.selecionados.length === 1) {
        imprimirDetalhado(state.selecionados[0]);
      } else {
        imprimirListagem(state.selecionados);
      }
    }

    function verificaVisualizarDFe(codigo: string): boolean {
      if (UtilitarioGeral.valorValido(codigo)) {
        const notaFiscalServico = state.notasServicos.find((c) => c.codigo === Number(codigo));
        if (notaFiscalServico !== undefined) {
          return (UtilitarioGeral.valorValido(notaFiscalServico.chave) && notaFiscalServico.status === EStatusNotaFiscalServico.Autorizada);
        }
      }
      return false;
    }

    function verificaEmissao(codigo: string): boolean {
      if (UtilitarioGeral.valorValido(codigo)) {
        const notaFiscalServico = state.notasServicos.find((c) => c.codigo === Number(codigo));
        if (notaFiscalServico !== undefined) {
          return (notaFiscalServico.status === EStatusNotaFiscalServico.Pendente && notaFiscalServico.totalNotaFiscal > 0);
        }
      }
      return false;
    }

    async function visualizarDFe(codigo: string) {
      if (UtilitarioGeral.valorValido(codigo)) {
        const notaFiscalServico = state.notasServicos.find((c) => c.codigo === Number(codigo));
        if (notaFiscalServico !== undefined) {
          const retorno = await servicoNotaFiscalServico.visualizar(notaFiscalServico.codigo);
          if (retorno !== undefined) {
            if (retorno.sucesso) {
              if (UtilitarioGeral.valorValido(retorno.linkPdf)) {
                state.arquivosPdf = [{ nome: '', link: retorno.linkPdf } as IArquivoPdf];
              }
            } else if (UtilitarioGeral.valorValido(retorno.mensagem)) {
              apresentarMensagemAlerta(retorno.mensagem);
            }
          }
        }
      }
    }

    function apresentarEnvioEmail(codigo: string) {
      if (UtilitarioGeral.valorValido(codigo)) {
        const notaFiscalServico = state.notasServicos.find((c) => c.codigo === Number(codigo));
        if (notaFiscalServico !== undefined) {
          state.notaServicoEnvioEmail = notaFiscalServico;
          state.codigoPessoaEmail = notaFiscalServico.codigoTomador;
          state.apresentarEnvioEmail = true;
        }
      }
    }

    async function emitirNfse(codigo: string) {
      if (UtilitarioGeral.valorValido(codigo)) {
        const notaFiscalServico = state.notasServicos.find((c) => c.codigo === Number(codigo));
        if (notaFiscalServico !== undefined) {
          const retorno = await servicoNotaFiscalServico.emitir(notaFiscalServico.codigo);
          if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
            apresentarMensagemSucesso(retorno.mensagem);
          } else {
            apresentarMensagemAlerta(retorno.mensagem);
          }
        }
      }
    }

    function obtemIdentificadoresAutorizados(): string[] {
      const identificadoresAutorizados:string[] = [];
      if (UtilitarioGeral.validaLista(state.selecionados)) {
        state.selecionados.forEach((codigo) => {
          const notaFiscalServico = state.notasServicos.find((c) => c.codigo === Number(codigo));
          if (notaFiscalServico !== undefined) {
            if (notaFiscalServico.status === EStatusNotaFiscalServico.Autorizada) {
              identificadoresAutorizados.push(notaFiscalServico.identificadorNotaFiscalServico);
            }
          }
        });
      }
      return identificadoresAutorizados;
    }

    async function baixarPdfs() {
      const identificadoresAutorizados = obtemIdentificadoresAutorizados();
      if (UtilitarioGeral.validaLista(identificadoresAutorizados)) {
        const retorno = await servicoNotaFiscalServico.downloadPdfs(state.empresaSelecionada, identificadoresAutorizados);
        if (retorno !== undefined) {
          if (retorno.sucesso) {
            if (UtilitarioGeral.valorValido(retorno.link)) {
              UtilitarioGeral.downloadArquivo(retorno.link);
              return;
            }
          }

          if (UtilitarioGeral.valorValido(retorno.mensagem)) {
            apresentarMensagemAlerta(retorno.mensagem);
          }
        }
      } else {
        apresentarMensagemAlerta('Não identificamos nenhuma NFS-e autorizada.');
      }
    }

    async function baixarXmls() {
      const identificadoresAutorizados = obtemIdentificadoresAutorizados();
      if (UtilitarioGeral.validaLista(identificadoresAutorizados)) {
        const retorno = await servicoNotaFiscalServico.downloadXmls(state.empresaSelecionada, identificadoresAutorizados);
        if (retorno !== undefined) {
          if (retorno.sucesso) {
            if (UtilitarioGeral.valorValido(retorno.link)) {
              UtilitarioGeral.downloadArquivo(retorno.link);
              return;
            }
          }

          if (UtilitarioGeral.valorValido(retorno.mensagem)) {
            apresentarMensagemAlerta(retorno.mensagem);
          }
        }
      } else {
        apresentarMensagemAlerta('Não identificamos nenhuma NFS-e autorizada.');
      }
    }

    function obtemIdentificadoresEmProcessamento(): string[] {
      const identificadoresEmProcessamento:string[] = [];
      if (UtilitarioGeral.validaLista(state.notasServicos)) {
        state.notasServicos.forEach((notaFiscalServico) => {
          if (notaFiscalServico.status === EStatusNotaFiscalServico.EmProcessamento) {
            identificadoresEmProcessamento.push(notaFiscalServico.identificadorNotaFiscalServico);
          }
        });
      }
      return identificadoresEmProcessamento;
    }

    async function sincronizarProcessamentoComPrefeitura() {
      const identificadoresEmProcessamento = obtemIdentificadoresEmProcessamento();
      if (UtilitarioGeral.validaLista(identificadoresEmProcessamento)) {
        const retorno = await servicoNotaFiscalServico.verificarNFSsEmProcessamento(state.empresaSelecionada, identificadoresEmProcessamento);
        if (retorno !== undefined) {
          if (retorno.sucesso) {
            state.sincronizarProcessamento = false;
            apresentarMensagemSucesso(retorno.mensagem);
          } else {
            apresentarMensagemAlerta(retorno.mensagem);
          }
        }
      } else {
        apresentarMensagemAlerta('Não identificamos nenhuma NFS-e em processamento.');
      }
    }

    async function estrategiaParametroRota() {
      const visualizarNfse = String(route.query.visualizarNfse);
      if (UtilitarioGeral.validaCodigo(visualizarNfse)) {
        const retorno = await servicoNotaFiscalServico.visualizar(Number(visualizarNfse));
        if (retorno !== undefined) {
          if (retorno.sucesso) {
            if (UtilitarioGeral.valorValido(retorno.linkPdf)) {
              state.arquivosPdf = [{ nome: '', link: retorno.linkPdf } as IArquivoPdf];
            }
          } else if (UtilitarioGeral.valorValido(retorno.mensagem)) {
            apresentarMensagemAlerta(retorno.mensagem);
          }
        }
      } else {
        const editarCodigoNotaFiscalServico = String(route.query.editar);
        if (UtilitarioGeral.validaCodigo(editarCodigoNotaFiscalServico)) {
          editar(Number(editarCodigoNotaFiscalServico));
        }
      }
    }

    // Monitorar mudanças na rota
    watch(() => route.fullPath, () => {
      estrategiaParametroRota();
    });

    onBeforeMount(async () => {
      state.visualizarKanban = !UtilitarioDispositivo.larguraTelaMobile();
      state.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      telaBase.identificadorRecurso = state.identificadorRecurso;
      telaBase.identificadorPermissao = state.identificadorPermissao;
      preencherColunas();
      await obterPermissoes(ETipoPermissao.Dados);
      await preencherEmpresasDisponiveis();
      await defineEmpresasSelecionadasComPermissao();
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas([state.empresaSelecionada]);
      telaBase.propriedadesConsulta = await servicoNotaFiscalServico.obterPropriedadesConsulta();
      if (telaBase.propriedadesConsulta.length > 0) {
        state.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        state.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
      state.buscaAvancada.filtrosAdicionados = [];
      state.buscaAvancada.chaveFiltrosAdicionados = 0;
      state.dataAtual = await new ServicoSistema().obterDataAtual();
      await buscarNotasServicos();
      await estrategiaParametroRota();
      limparNotaFiscalServicoEnvioEmail();
    });

    function buscaRapidaDevolucao() {
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        await buscarNotasServicos();
      }, 600);
    }

    async function periodoAlterado() {
      if (state.filtroPeriodo !== EFiltroPeriodoMovimentoComercial.Personalizado) {
        await buscarNotasServicos();
      }
    }

    function textoCodigosSelecionados():string {
      if (state.qtdSelecionados === 1) { return '1 - Nota selecionada'; }

      if (state.qtdSelecionados > 1) { return `${state.qtdSelecionados} - Notas selecionadas`; }

      return '';
    }

    return {
      state,
      props,
      window,
      buscaRapidaDevolucao,
      EVinculoEmpresa,
      UtilitarioMascara,
      UtilitarioData,
      UtilitarioGeral,
      storeSistema,
      telaBase,
      incluir,
      editar,
      clickEditar,
      excluir,
      retomarEtapa,
      buscarNotasServicos,
      periodoAlterado,
      prencheDatasPeriodo,
      atualizarSelecaoEmpresas,
      adicionarAtalho,
      removerAtalho,
      atualizarSelecionadosEmMassa,
      selecionarCard,
      concluirEtapa,
      moverCard,
      atualizarSelecionados,
      atualizarOrdemCards,
      EFiltroPeriodoMovimentoComercial,
      atualizarSelecionadosKanban,
      cancelar,
      imprimir,
      imprimirDetalhado,
      verificaVisualizarDFe,
      visualizarDFe,
      acaoDesabilitada,
      apresentarEnvioEmail,
      enviarEmailNFse,
      verificaEmissao,
      emitirNfse,
      textoCodigosSelecionados,
      baixarPdfs,
      baixarXmls,
      sincronizarProcessamentoComPrefeitura,
    };
  },
});
