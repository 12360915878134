
import { computed, defineComponent } from 'vue';
import { IPessoaFiscal } from '@/models/Entidades/Cadastros/Pessoas/IPessoaFiscal';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarTipoAtividade from '@/components/Compartilhados/SelecionarTipoAtividade.vue';
import { ETipoPessoa } from '@/models/Enumeradores/Compartilhados/ETipoPessoa';

export default defineComponent({
  name: 'FiscalTributacaoPessoa',
  props: {
    tipoPessoa: {
      type: Number,
      required: true,
    },
    fiscal: {
      type: Object as ()=> IPessoaFiscal,
      required: true,
    },
  },
  components: {
    CampoNumerico,
    SelecionarTipoAtividade,
  },
  emits: ['update:fiscal'],
  setup(props, { emit }) {
    const computedFiscal = computed({
      get: () => props.fiscal,
      set: (valor: IPessoaFiscal) => {
        emit('update:fiscal', valor);
      },
    });

    function obterFunruralPadrao() {
      if (props.fiscal.funrural) {
        computedFiscal.value.aliquotaFunrural = 1.5;
      } else {
        computedFiscal.value.aliquotaFunrural = 0;
      }
    }

    return {
      props,
      ETipoPessoa,
      computedFiscal,
      obterFunruralPadrao,
    };
  },
});
