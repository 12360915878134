
import {
  computed, defineComponent, reactive,
} from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { INotaFiscalServicoImpostoTotal } from '@/models/Entidades/Fiscal/INotaFiscalServico';

export default defineComponent({
  name: 'NotaFiscalServicoImpostos',
  props: {
    impostos: {
      type: Object as () => INotaFiscalServicoImpostoTotal,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Card,
    CampoNumerico,
  },
  emits: ['update:impostos', 'totalAlterado'],
  setup(props, { emit }) {
    const state = reactive({});

    const computedImpostos = computed({
      get: () => props.impostos,
      set: (val: INotaFiscalServicoImpostoTotal) => {
        emit('update:impostos', val);
      },
    });

    function totalAlterado() {
      emit('totalAlterado');
    }

    return {
      props,
      state,
      computedImpostos,
      totalAlterado,
    };
  },
});
