
import { computed, defineComponent, reactive } from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ITipoContato } from '@/models/Entidades/Cadastros/Pessoas/Contatos/ITipoContato';
import ServicoTipoContato from '@/servicos/Cadastros/Pessoas/ServicoTipoContato';

export default defineComponent({
  name: 'SelecionarTipoContato',
  props: {
    codigoSelecionado: {
      type: Number || undefined,
    },
    empresas: {
      type: Array as () => number[],
      default: () => [],
    },
    relacoes: {
      type: Array as () => number[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoSelecionado'],
  setup(props, { emit }) {
    const servicoTipoContato = new ServicoTipoContato();

    const state = reactive({
      listaOpcoesTipoContatos: [] as IOption[],
      listaTipoContatos: [] as ITipoContato[],
      relacoesFiltradas: [] as number[],
      empresasFiltradas: [] as number[],
    });
    state.relacoesFiltradas = [];
    state.empresasFiltradas = [];

    async function obterTipoContatoPorCodigo() {
      if (state.relacoesFiltradas !== props.relacoes || state.empresasFiltradas !== props.empresas) {
        state.listaTipoContatos = await servicoTipoContato.obterTodos(props.empresas, props.relacoes);
        if (UtilitarioGeral.validaLista(state.listaTipoContatos)) {
          state.listaOpcoesTipoContatos = state.listaTipoContatos.filter((c) => c.ativo === true).map((item) => ({
            label: item.descricao,
            value: item.codigo,
          }));
        }
        state.relacoesFiltradas = props.relacoes;
        state.empresasFiltradas = props.empresas;
      }
    }

    async function obterListaTipos() {
      if (!UtilitarioGeral.validaLista(state.listaTipoContatos)) {
        state.listaTipoContatos = await servicoTipoContato.obterTodos(props.empresas, props.relacoes);
      }

      if (UtilitarioGeral.validaLista(state.listaTipoContatos)) {
        state.listaOpcoesTipoContatos = state.listaTipoContatos.filter((c) => c.ativo === true).map((item) => ({
          label: item.descricao,
          value: item.codigo,
        }));
      }
    }

    function obterTipoContato():any {
      obterTipoContatoPorCodigo();
      return props.codigoSelecionado;
    }

    const computedCodigoSelecionado = computed({
      get: () => obterTipoContato(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
      },
    });

    return {
      props,
      state,
      obterListaTipos,
      computedCodigoSelecionado,
    };
  },
});
