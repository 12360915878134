
import {
  computed, defineComponent, reactive,
} from 'vue';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IOption } from '@/core/models/AntDesign/IOption';
import { ETipoPessoa } from '@/models/Enumeradores/Compartilhados/ETipoPessoa';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import Icone from '@/core/components/Icone.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarCidade from '@/components/Cadastros/Localizacoes/Cidades/SelecionarCidade.vue';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import SelecionarCategoriaCnh from '@/components/Compartilhados/SelecionarCategoriaCnh.vue';

export default defineComponent({
  name: 'DadosPessoais',
  props: {
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    codigoCidadeNascimento: {
      type: Number || undefined,
      required: true,
    },
    foto: {
      type: String,
      default: '',
    },
    estadoCivil: {
      type: Number || undefined,
      required: true,
    },
    rg: {
      type: String,
      default: '',
    },
    orgaoEmissor: {
      type: String,
      default: '',
    },
    dataNascimento: {
      type: String,
      default: '',
    },
    nomePai: {
      type: String,
      default: '',
    },
    nomeMae: {
      type: String,
      default: '',
    },
    cnh: {
      type: String,
      default: '',
    },
    categoriaCnh: {
      type: Number || undefined,
      required: true,
    },
    dataVencimentoCnh: {
      type: String,
      default: '',
    },
  },
  components: {
    Icone,
    SelecionarData,
    SelecionarCidade,
    SelecionarCategoriaCnh,
  },
  emits: ['update:codigoCidadeNascimento', 'update:foto', 'update:estadoCivil',
    'update:rg', 'update:orgaoEmissor', 'update:dataNascimento', 'update:nomePai',
    'update:nomeMae', 'update:cnh', 'update:categoriaCnh', 'update:dataVencimentoCnh'],
  setup(props, { emit }) {
    const { montaOpcoesComListaItemGenerico, apresentarMensagemAlerta } = useTelaBase();

    const servicoPessoa = new ServicoPessoa();

    const state = reactive({
      carregandoFoto: false,
      carregandoEstadoCivil: false,
      listaArquivo: [],
      listaEstadoCivil: [] as IOption[],
      listaCategoriaCNH: [] as IOption[],
    });

    const computedCodigoCidadeNascimento = computed({
      get: () => props.codigoCidadeNascimento,
      set: (valor: number) => {
        emit('update:codigoCidadeNascimento', valor);
      },
    });

    const computedFoto = computed({
      get: () => props.foto,
      set: (valor: string) => {
        emit('update:foto', valor);
      },
    });

    const computedRg = computed({
      get: () => props.rg,
      set: (valor: string) => {
        emit('update:rg', valor);
      },
    });
    const computedEstadoCivil = computed({
      get: () => props.estadoCivil,
      set: (valor: number) => {
        emit('update:estadoCivil', valor);
      },
    });

    const computedOrgaoEmissor = computed({
      get: () => props.orgaoEmissor,
      set: (valor: string) => {
        emit('update:orgaoEmissor', valor);
      },
    });

    const computedDataNascimento = computed({
      get: () => props.dataNascimento,
      set: (valor: string) => {
        emit('update:dataNascimento', valor);
      },
    });

    const computedNomePai = computed({
      get: () => props.nomePai,
      set: (valor: string) => {
        emit('update:nomePai', valor);
      },
    });

    const computedNomeMae = computed({
      get: () => props.nomeMae,
      set: (valor: string) => {
        emit('update:nomeMae', valor);
      },
    });

    const computedCnh = computed({
      get: () => props.cnh,
      set: (valor: string) => {
        emit('update:cnh', valor);
      },
    });

    const computedCategoriaCnh = computed({
      get: () => props.categoriaCnh,
      set: (valor: number) => {
        emit('update:categoriaCnh', valor);
      },
    });

    const computedDataVencimentoCnh = computed({
      get: () => props.dataVencimentoCnh,
      set: (valor: string) => {
        emit('update:dataVencimentoCnh', valor);
      },
    });

    (async () => {
      const listaEstadoCivil = await servicoPessoa.obterListaEstadoCivil();
      state.listaEstadoCivil = montaOpcoesComListaItemGenerico(listaEstadoCivil);

      const listaCategoriaCNH = await servicoPessoa.obterListaCategoriasCNH();
      state.listaCategoriaCNH = montaOpcoesComListaItemGenerico(listaCategoriaCNH);
    })();

    async function enviarFoto(arquivo:any) {
      state.carregandoFoto = true;
      const retornoArquivo = await servicoPessoa.enviarFoto(arquivo);
      if (retornoArquivo.status === EStatusRetornoRequisicao.Sucesso) {
        computedFoto.value = retornoArquivo.linkArquivo;
      } else {
        apresentarMensagemAlerta(retornoArquivo.mensagem);
      }
      state.carregandoFoto = false;
    }

    async function removerFoto() {
      state.carregandoFoto = true;
      const retornoArquivo = await servicoPessoa.removerFoto(computedFoto.value);
      if (retornoArquivo.status === EStatusRetornoRequisicao.Sucesso) {
        computedFoto.value = '';
      } else if (retornoArquivo.status === EStatusRetornoRequisicao.Alerta) {
        computedFoto.value = '';
        apresentarMensagemAlerta(retornoArquivo.mensagem);
      }
      state.carregandoFoto = false;
    }
    return {
      props,
      computedCodigoCidadeNascimento,
      computedFoto,
      computedEstadoCivil,
      computedRg,
      computedOrgaoEmissor,
      computedDataNascimento,
      computedNomePai,
      computedNomeMae,
      computedCnh,
      computedCategoriaCnh,
      computedDataVencimentoCnh,
      state,
      ETipoPessoa,
      UtilitarioMascara,
      enviarFoto,
      removerFoto,
    };
  },
});
