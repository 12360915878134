export enum ESazonalidade {
  semanal = 1,
  quinzenal = 2,
  mensal = 3,
  bimestral = 4,
  trimestral = 5,
  semestral = 6,
  anual = 7,
  customizada = 8,
}
