import ApiERP from '@/core/conectores/ApiERP';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IOrdenacaoRegistro } from '@/core/models/IOrdenacaoRegistro';
import { IRetornoComunicacaoDFe } from '@/core/models/IRetornoComunicacaoDFe';
import { IRetornoConsultaDFe } from '@/core/models/IRetornoConsultaDFe';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IRetornoVisualizarDFe } from '@/core/models/IRetornoVisualizarDFe';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { IDTOTituloFinanceiroNotaFiscalServico } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiroNotaFiscalServico';
import { IDTONotaFiscalServicoTituloFinanceiro } from '@/models/DTO/Fiscal/NotasFiscaisServico/IDTONotaFiscalServicoTituloFinanceiro';
import { INotaFiscalServico } from '@/models/Entidades/Fiscal/INotaFiscalServico';
import { EStatusNotaFiscalServico } from '@/models/Enumeradores/Fiscal/NotasFiscaisServicos/EStatusNotaFiscalServico';
import { IParametrosConsultaNotaFiscalServico } from '@/models/ParametrosRequisicao/Fiscal/IParametrosConsultaNotaFiscalServico';
import { IRetornoNotaFiscalServico } from '@/models/Retornos/Fiscal/IRetornoNotaFiscalServico';

/**
 * Serviço de Notas Fiscais de Serviço
 * Fornece todas regras de negócios e lógicas relacionado a nota fiscal de serviço.
 */

class ServicoNotaFiscalServico {
    endPoint = 'notas-fiscais-servicos';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async obterSerie(empresa:number): Promise<string> {
      const result: any = await this.apiERP.get(`${this.endPoint}/serie/empresa/${empresa}`);
      return result.data;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsultaNotaFiscalServico, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      let parametrosAdicionais = '';
      if (parametrosConsulta.empresas !== undefined && parametrosConsulta.empresas.length > 0) {
        parametrosConsulta.empresas.forEach((codigoEmpresa) => {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `Empresas=${codigoEmpresa}`;
        });
      }

      if (parametrosConsulta.periodo !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `periodo=${parametrosConsulta.periodo}`;

        parametrosAdicionais += `&dataInicial=${parametrosConsulta.dataInicial}`;
        parametrosAdicionais += `&dataFinal=${parametrosConsulta.dataFinal}`;
      }

      if (parametrosConsulta.valorBuscaRapida !== undefined) {
        if (UtilitarioGeral.valorValido(parametrosConsulta.valorBuscaRapida)) {
          parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
          parametrosAdicionais += `valorBuscaRapida=${parametrosConsulta.valorBuscaRapida}`;
        }
      }

      if (filtros !== undefined) {
        const jsonFiltros = JSON.stringify(filtros);
        parametrosAdicionais += `&Filtros=${jsonFiltros}`;
      }
      if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
        parametrosAdicionais += `&NumeroPagina=${parametrosConsulta.numeroPagina}`;
      }
      if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
        parametrosAdicionais += `&QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
      }
      if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
        parametrosAdicionais += `&QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
      }
      if (parametrosConsulta.ordenacao !== undefined) {
        parametrosConsulta.ordenacao.forEach((item) => {
          parametrosAdicionais += `&Ordenacao=${item}`;
        });
      }
      if (parametrosConsulta.codigosSelecionados !== undefined) {
        parametrosConsulta.codigosSelecionados.forEach((codigo) => {
          parametrosAdicionais += `&CodigosSelecionados=${codigo}`;
        });
      }

      const result: any = await this.apiERP.get(`${this.endPoint}${parametrosAdicionais}`);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data;
      return listaPaginada;
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      const parametrosEmpresas = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);
      let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsulta(parametrosConsulta, filtros);
      if (codigosSelecionados !== undefined) {
        parametrosAdicionais += this.apiERP.obterParametroCodigosSelecionados(codigosSelecionados);
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/relatorio-padrao/${tipoArquivo}${parametrosEmpresas}${parametrosAdicionais}`);
      return result.data;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async criarPorTitulosFinanceiros(dados: IDTONotaFiscalServicoTituloFinanceiro): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/titulos-financeiros`, dados);
      return result.data;
    }

    public async prepararTitulosFinanceirosParaEmissao(empresa: number, titulosFinanceiros: IDTOTituloFinanceiro[]): Promise<IDTOTituloFinanceiroNotaFiscalServico[]> {
      const result: any = await this.apiERP.post(`${this.endPoint}/titulos-financeiros/preparar/empresa/${empresa}`, titulosFinanceiros);
      return result.data;
    }

    public async incluir(nota: INotaFiscalServico): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, nota);
      return result.data;
    }

    public async alterar(nota: INotaFiscalServico): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${nota.codigo}`, nota);
      return result.data;
    }

    public async obter(codigo: number, obterServicos?:boolean): Promise<INotaFiscalServico> {
      let parametrosAdicionais = '';
      if (obterServicos !== undefined) {
        parametrosAdicionais += `?obterServicos=${obterServicos}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}${parametrosAdicionais}`);
      return result.data;
    }

    public async consultar(codigo: number): Promise<IRetornoConsultaDFe> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/consultar`);
      return result.data;
    }

    public async emitir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/${codigo}/emitir`);
      return result.data;
    }

    public async visualizar(codigo: number): Promise<IRetornoVisualizarDFe> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/visualizar`);
      return result.data;
    }

    public async enviarEmail(codigo: number, emails: string[]): Promise<IRetornoComunicacaoDFe> {
      const result: any = await this.apiERP.post(`${this.endPoint}/${codigo}/enviar/email`, emails);
      return result.data;
    }

    public async downloadXmls(empresa: number, identificadores: string[]): Promise<IRetornoComunicacaoDFe> {
      const result: any = await this.apiERP.post(`${this.endPoint}/empresa/${empresa}/downloads/xmls`, identificadores);
      return result.data;
    }

    public async downloadPdfs(empresa: number, identificadores: string[]): Promise<IRetornoComunicacaoDFe> {
      const result: any = await this.apiERP.post(`${this.endPoint}/empresa/${empresa}/downloads/pdfs`, identificadores);
      return result.data;
    }

    public async verificarNFSsEmProcessamento(empresa: number, identificadores: string[]): Promise<IRetornoComunicacaoDFe> {
      const result: any = await this.apiERP.post(`${this.endPoint}/empresa/${empresa}/emissao/em-processamento`, identificadores);
      return result.data;
    }

    public async excluir(codigo: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluirVarios(empresa: number, codigosNotasFiscaisServicos: number[]): Promise<IRetornoRequisicao> {
      let parametrosAdicionais = '';
      for (let contador = 0; contador < codigosNotasFiscaisServicos.length; contador += 1) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Codigos=${codigosNotasFiscaisServicos[contador]}`;
      }

      const result: any = await this.apiERP.delete(`${this.endPoint}/varios/empresa/${empresa}${parametrosAdicionais}`);
      return result.data;
    }

    public async imprimir(codigo: number, empresa: number, tipoArquivo: ETipoArquivo): Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.get(`${this.endPoint}/impressao/${codigo}/empresa/${empresa}/tipo-arquivo/${tipoArquivo}`);
      return result.data;
    }

    public async atualizarOrdenacao(ordenacaoRegistros: IOrdenacaoRegistro[], empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/ordenacao/empresa/${empresa}`, ordenacaoRegistros);
      return result.data;
    }

    public async alterarEtapa(codigo: number, empresa: number, statusNovo: EStatusNotaFiscalServico): Promise<IRetornoNotaFiscalServico> {
      const result: any = await this.apiERP.put(`${this.endPoint}/etapa/${codigo}/empresa/${empresa}/status-novo/${statusNovo}`);
      return result.data;
    }

    public async alterarEtapaVarios(empresa: number, proximaEtapa: boolean, cancelamento: boolean, notasFiscaisServicos: number[]): Promise<IRetornoNotaFiscalServico> {
      let parametrosAdicionais = '';
      for (let contador = 0; contador < notasFiscaisServicos.length; contador += 1) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `NotasFiscaisServicos=${notasFiscaisServicos[contador]}`;
      }

      const result: any = await this.apiERP.put(`${this.endPoint}/etapa-varios/empresa/${empresa}/proxima-etapa/${proximaEtapa}/cancelamento/${cancelamento}${parametrosAdicionais}`);
      return result.data;
    }
}
export default ServicoNotaFiscalServico;
