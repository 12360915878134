
import {
  defineComponent, onBeforeMount, reactive, watch,
} from 'vue';
import { Dayjs } from 'dayjs';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'SelecionarDataInicialFinal',
  props: {
    dataInicialSelecionada: {
      type: String,
      default: '',
    },
    dataFinalSelecionada: {
      type: String,
      default: '',
    },
    horaMinuto: {
      type: Boolean,
      default: false,
    },
    horaMinutoSegundo: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: Array as () => string[],
      default: () => ['Data Inicial', 'Data Final'],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classCss: {
      type: String,
      default: '',
    },
  },
  emits: ['update:dataInicialSelecionada', 'update:dataFinalSelecionada', 'update:horaMinutoSegundo', 'change'],
  setup(props, { emit }) {
    const state = reactive({
      datasSelecionadas: [] as Dayjs[],
    });

    function obterFormatoData() {
      if (props.horaMinutoSegundo) {
        return UtilitarioData.formatoDataDiaMesAnoHoraMinutoSegundo;
      } if (props.horaMinuto) {
        return UtilitarioData.formatoDataDiaMesAnoHoraMinuto;
      }
      return UtilitarioData.formatoDataDiaMesAno;
    }

    function change() {
      if (state.datasSelecionadas === undefined || state.datasSelecionadas === null) {
        emit('update:dataInicialSelecionada', '');
      } else if (props.horaMinuto || props.horaMinutoSegundo) {
        emit('update:dataInicialSelecionada', UtilitarioData.converterDataHoraMinutoSegundoJson(state.datasSelecionadas[0]));
      } else {
        emit('update:dataInicialSelecionada', UtilitarioData.converterDataJson(state.datasSelecionadas[0]));
      }

      if (state.datasSelecionadas === undefined || state.datasSelecionadas === null) {
        emit('update:dataFinalSelecionada', '');
      } else if (props.horaMinuto || props.horaMinutoSegundo) {
        emit('update:dataFinalSelecionada', UtilitarioData.converterDataHoraMinutoSegundoJson(state.datasSelecionadas[1]));
      } else {
        emit('update:dataFinalSelecionada', UtilitarioData.converterDataJson(state.datasSelecionadas[1]));
      }

      emit('change', props.dataInicialSelecionada, props.dataFinalSelecionada);
    }

    function alterarDataInicial() {
      if (UtilitarioGeral.valorValido(props.dataInicialSelecionada)) {
        let dataInicial:Dayjs;
        if (props.horaMinuto) {
          dataInicial = UtilitarioData.converterDataHoraMinutoParaDayJs(props.dataInicialSelecionada);
        } if (props.horaMinutoSegundo) {
          dataInicial = UtilitarioData.converterDataHoraMinutoSegundoParaDayJs(props.dataInicialSelecionada);
        }
        dataInicial = UtilitarioData.converterDataParaDayJs(props.dataInicialSelecionada);

        if (state.datasSelecionadas.length === 0) {
          state.datasSelecionadas.push(dataInicial);
        } else {
          state.datasSelecionadas[0] = dataInicial;
        }
      }
    }

    function alterarDataFinal() {
      if (UtilitarioGeral.valorValido(props.dataFinalSelecionada)) {
        let dataFinal:Dayjs;
        if (props.horaMinuto) {
          dataFinal = UtilitarioData.converterDataHoraMinutoParaDayJs(props.dataFinalSelecionada);
        } if (props.horaMinutoSegundo) {
          dataFinal = UtilitarioData.converterDataHoraMinutoSegundoParaDayJs(props.dataFinalSelecionada);
        }
        dataFinal = UtilitarioData.converterDataParaDayJs(props.dataFinalSelecionada);

        if (state.datasSelecionadas.length <= 1) {
          state.datasSelecionadas.push(dataFinal);
        } else {
          state.datasSelecionadas[1] = dataFinal;
        }
      }
    }

    watch(() => props.dataInicialSelecionada, () => {
      alterarDataInicial();
    });

    watch(() => props.dataFinalSelecionada, () => {
      alterarDataFinal();
    });

    onBeforeMount(async () => {
      alterarDataInicial();
      alterarDataFinal();
    });

    return {
      props,
      state,
      change,
      obterFormatoData,
    };
  },
});
