
import {
  computed, defineComponent, ref,
} from 'vue';
import { useSelecionarBase } from '@/core/composables/SelecionarBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoUsuario from '@/servicos/MeuSistema/ServicoUsuario';
import CampoCarregando from '@/core/components/Tela/CampoCarregando.vue';

export default defineComponent({
  name: 'SelecionarUsuario',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    descricaoSelecionado: {
      type: String,
      default: '',
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    usuariosEspecificos: {
      type: Array as () => number[],
      default: [] as number[],
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    dataAttributeColuna: {
      type: String,
      default: '',
    },
    classCss: {
      type: String,
      default: '',
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CampoCarregando,
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:descricaoSelecionado', 'change', 'blur'],
  setup(props, { emit }) {
    const {
      selecionarBase, montaOpcoesComListaConsultaRapida, verificacaoPreBusca, verificacaoUltimaPesquisa, aguardarConclusaoCarregamento,
      comportamentoPadraoSemResultado, instanciaParametrosConsultaRapidaPorCodigo, instanciaParametrosConsultaRapidaPesquisa,
    } = useSelecionarBase(props, emit);

    const refSelecionarUsuario = ref<HTMLElement | null>(null);

    let debounce = 0;
    const servicoUsuario = new ServicoUsuario();

    function preencheListaOpcoes(): void {
      if (UtilitarioGeral.validaLista(selecionarBase.listaItensConsultaRapida)) {
        selecionarBase.listaOpcoes = montaOpcoesComListaConsultaRapida(selecionarBase.listaItensConsultaRapida);
      }
    }

    async function obterUsuarioPorCodigo(valor: any) {
      if (!UtilitarioGeral.valorValido(valor)) {
        return;
      } if (valor.length === 1) {
        if (!verificacaoPreBusca(valor)) {
          return;
        }
      }
      if (!verificacaoPreBusca(valor) && !props.varios) {
        return;
      }
      selecionarBase.listaOpcoes = [];

      const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPorCodigo(valor, true);
      selecionarBase.listaItensConsultaRapida = await servicoUsuario.consultaRapida(parametrosConsultaRapida);
      if (props.usuariosEspecificos.length > 0) {
        selecionarBase.listaItensConsultaRapida = selecionarBase.listaItensConsultaRapida.filter((i) => props.usuariosEspecificos.includes(i.codigo));
      }
      preencheListaOpcoes();
      comportamentoPadraoSemResultado();
    }

    async function pesquisarUsuario(valorPesquisa: any) {
      selecionarBase.emDigitacao = true;
      console.log('pre');
      if (!verificacaoUltimaPesquisa(valorPesquisa)) return;
      selecionarBase.buscandoDados = true;
      selecionarBase.listaOpcoes = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPesquisa(valorPesquisa, true);
        selecionarBase.listaItensConsultaRapida = await servicoUsuario.consultaRapida(parametrosConsultaRapida);
        if (props.usuariosEspecificos.length > 0) {
          selecionarBase.listaItensConsultaRapida = selecionarBase.listaItensConsultaRapida.filter((i) => props.usuariosEspecificos.includes(i.codigo));
        }
        preencheListaOpcoes();
        selecionarBase.valorUltimaPesquisa = valorPesquisa;
        selecionarBase.buscandoDados = false;
      }, 600);
    }

    function obterUsuario(): any {
      obterUsuarioPorCodigo(props.codigoSelecionado);
      return (props.codigoSelecionado === 0 ? undefined : props.codigoSelecionado);
    }

    const computedDescricaoSelecionado = computed({
      get: () => props.descricaoSelecionado,
      set: (val: string) => {
        emit('update:descricaoSelecionado', val);
      },
    });

    const computedCodigoSelecionado = computed({
      get: () => obterUsuario(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
        const itemConsulta = selecionarBase.listaItensConsultaRapida.find((c) => c.codigo === val);
        if (itemConsulta?.textoIdentificacao !== undefined && itemConsulta?.textoIdentificacao !== '') {
          computedDescricaoSelecionado.value = itemConsulta?.textoIdentificacao;
        }
      },
    });

    function obterUsuarios(): number[] {
      obterUsuarioPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterUsuarios(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    function blur() {
      selecionarBase.emDigitacao = false;
      emit('blur');
    }

    function focus() {
      if (refSelecionarUsuario.value) {
        refSelecionarUsuario.value.focus();
      }
    }

    return {
      props,
      selecionarBase,
      refSelecionarUsuario,
      pesquisarUsuario,
      aguardarConclusaoCarregamento,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      computedDescricaoSelecionado,
      change,
      focus,
      blur,
      UtilitarioGeral,
    };
  },
});
