import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["maxlength"]
const _hoisted_2 = ["maxlength"]
const _hoisted_3 = {
  key: 4,
  class: "ant-input-wrapper ant-input-group"
}
const _hoisted_4 = { class: "ant-input-group-addon" }
const _hoisted_5 = ["maxlength"]
const _hoisted_6 = ["maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_selecionar_valor_campo_personalizado = _resolveComponent("selecionar-valor-campo-personalizado")!
  const _component_selecionar_item_campo_personalizado = _resolveComponent("selecionar-item-campo-personalizado")!
  const _component_selecionar_data = _resolveComponent("selecionar-data")!
  const _component_selecionar_hora = _resolveComponent("selecionar-hora")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!

  return (_openBlock(), _createBlock(_component_a_skeleton, {
    active: "",
    loading: _ctx.state.carregando,
    paragraph: { rows: 0 }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form_item, { class: "ss-margin-campos" }, {
        label: _withCtx(() => [
          _createElementVNode("label", null, _toDisplayString(_ctx.props.campo.nome), 1),
          (_ctx.UtilitarioGeral.valorValido(_ctx.props.campo.info))
            ? (_openBlock(), _createBlock(_component_a_tooltip, {
                key: 0,
                placement: "top",
                title: _ctx.props.campo.info
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icone, {
                    class: "ss-icone-informacao-campo",
                    nome: "informacao-campo"
                  })
                ]),
                _: 1
              }, 8, ["title"]))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_tooltip, {
            placement: "top",
            title: _ctx.props.campo.tooltip
          }, {
            default: _withCtx(() => [
              (_ctx.props.campo.tipoCampo === _ctx.ETipoCampoPersonalizado.Texto)
                ? _withDirectives((_openBlock(), _createElementBlock("input", {
                    key: 0,
                    class: "ant-input",
                    maxlength: _ctx.obterTamanhoMaximo(),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedCampo.valores[0].valor) = $event))
                  }, null, 8, _hoisted_1)), [
                    [_vModelText, _ctx.computedCampo.valores[0].valor]
                  ])
                : (_ctx.props.campo.tipoCampo === _ctx.ETipoCampoPersonalizado.AreaTexto)
                  ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                      key: 1,
                      rows: "1",
                      class: "ant-input",
                      maxlength: _ctx.obterTamanhoMaximo(),
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedCampo.valores[0].valor) = $event))
                    }, null, 8, _hoisted_2)), [
                      [_vModelText, _ctx.computedCampo.valores[0].valor]
                    ])
                  : (_ctx.props.campo.tipoCampo === _ctx.ETipoCampoPersonalizado.Inteiro)
                    ? (_openBlock(), _createBlock(_component_campo_numerico, {
                        key: 2,
                        mascara: _ctx.props.campo.mascara,
                        quantidadeCasasDecimais: 0,
                        valor: _ctx.state.valorNumerico,
                        "onUpdate:valor": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.valorNumerico) = $event)),
                        onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.atualizarValorNumerico()))
                      }, null, 8, ["mascara", "valor"]))
                    : (_ctx.props.campo.tipoCampo === _ctx.ETipoCampoPersonalizado.Fracionado)
                      ? (_openBlock(), _createBlock(_component_campo_numerico, {
                          key: 3,
                          quantidadeCasasDecimais: 2,
                          valor: _ctx.state.valorNumerico,
                          "onUpdate:valor": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.valorNumerico) = $event)),
                          onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.atualizarValorNumerico()))
                        }, null, 8, ["valor"]))
                      : (_ctx.props.campo.tipoCampo === _ctx.ETipoCampoPersonalizado.Email)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                            _createElementVNode("span", _hoisted_4, [
                              _createVNode(_component_icone, {
                                class: "ss-icone",
                                nome: "email"
                              })
                            ]),
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              class: "ant-input",
                              maxlength: _ctx.obterTamanhoMaximo(),
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedCampo.valores[0].valor) = $event))
                            }, null, 8, _hoisted_5), [
                              [_vModelText, _ctx.computedCampo.valores[0].valor]
                            ])
                          ]))
                        : (_ctx.props.campo.tipoCampo === _ctx.ETipoCampoPersonalizado.Senha)
                          ? _withDirectives((_openBlock(), _createElementBlock("input", {
                              key: 5,
                              type: "password",
                              class: "ant-input",
                              maxlength: _ctx.obterTamanhoMaximo(),
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.computedCampo.valores[0].valor) = $event))
                            }, null, 8, _hoisted_6)), [
                              [_vModelText, _ctx.computedCampo.valores[0].valor]
                            ])
                          : (_ctx.props.campo.tipoCampo === _ctx.ETipoCampoPersonalizado.ComboBox)
                            ? (_openBlock(), _createBlock(_component_selecionar_valor_campo_personalizado, {
                                key: 6,
                                valores: _ctx.props.campo.itens,
                                valor: _ctx.computedCampo.valores[0].valor,
                                "onUpdate:valor": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.computedCampo.valores[0].valor) = $event))
                              }, null, 8, ["valores", "valor"]))
                            : (_ctx.props.campo.tipoCampo === _ctx.ETipoCampoPersonalizado.ListaSelecao)
                              ? (_openBlock(), _createBlock(_component_selecionar_item_campo_personalizado, {
                                  key: 7,
                                  varios: true,
                                  codigoCampoPersonalizado: _ctx.props.campo.codigo,
                                  codigosSelecionados: _ctx.state.itensSelecionados,
                                  "onUpdate:codigosSelecionados": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.itensSelecionados) = $event)),
                                  onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.atualizarValoresSelecionados()))
                                }, null, 8, ["codigoCampoPersonalizado", "codigosSelecionados"]))
                              : (_ctx.props.campo.tipoCampo === _ctx.ETipoCampoPersonalizado.Data)
                                ? (_openBlock(), _createBlock(_component_selecionar_data, {
                                    key: 8,
                                    dataSelecionada: _ctx.computedCampo.valores[0].valor,
                                    "onUpdate:dataSelecionada": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.computedCampo.valores[0].valor) = $event))
                                  }, null, 8, ["dataSelecionada"]))
                                : (_ctx.props.campo.tipoCampo === _ctx.ETipoCampoPersonalizado.Hora)
                                  ? (_openBlock(), _createBlock(_component_selecionar_hora, {
                                      key: 9,
                                      horaSelecionada: _ctx.computedCampo.valores[0].valor,
                                      "onUpdate:horaSelecionada": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.computedCampo.valores[0].valor) = $event))
                                    }, null, 8, ["horaSelecionada"]))
                                  : (_ctx.props.campo.tipoCampo === _ctx.ETipoCampoPersonalizado.Check)
                                    ? (_openBlock(), _createBlock(_component_a_checkbox, {
                                        key: 10,
                                        class: "ss-checkbox-grande",
                                        checked: _ctx.state.valorBoolean,
                                        "onUpdate:checked": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.state.valorBoolean) = $event)),
                                        onChange: _cache[14] || (_cache[14] = ($event: any) => (_ctx.atualizarValorBooleano()))
                                      }, null, 8, ["checked"]))
                                    : (_ctx.props.campo.tipoCampo === _ctx.ETipoCampoPersonalizado.Switch)
                                      ? (_openBlock(), _createBlock(_component_a_switch, {
                                          key: 11,
                                          checked: _ctx.state.valorBoolean,
                                          "onUpdate:checked": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.state.valorBoolean) = $event)),
                                          onChange: _cache[16] || (_cache[16] = ($event: any) => (_ctx.atualizarValorBooleano()))
                                        }, null, 8, ["checked"]))
                                      : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["title"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["loading"]))
}