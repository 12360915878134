import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09d021e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xl-21 ant-col-xs-21" }
const _hoisted_3 = { class: "ss-titulo" }
const _hoisted_4 = {
  key: 0,
  class: "ant-col ant-col-xl-3 ant-col-xs-3",
  style: {"padding-right":"10px"}
}
const _hoisted_5 = { class: "ant-row ss-div-corpo" }
const _hoisted_6 = {
  class: "ant-row",
  style: {"flex-wrap":"nowrap"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_coluna_kanban = _resolveComponent("coluna-kanban")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "titulo", {}, () => [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.props.titulo), 1)
        ], true)
      ]),
      (_ctx.props.switchListagem)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_a_radio_group, {
              value: _ctx.computedVisualizacaoKanban,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedVisualizacaoKanban) = $event)),
              "button-style": "solid"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_tooltip, {
                  placement: "top",
                  title: "Kanban"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio_button, {
                      value: true,
                      style: {"padding-top":"5px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_icone, { nome: "mapa" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_tooltip, {
                  placement: "top",
                  title: "Listagem"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio_button, {
                      value: false,
                      style: {"padding-top":"5px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_icone, { nome: "menu" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedColunas, (coluna, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "ant-col",
            key: index
          }, [
            _createVNode(_component_coluna_kanban, {
              coluna: coluna,
              onSelecaoCardAlterada: _ctx.selecaoCardAlterada,
              onColunaCardAlterada: _ctx.colunaCardAlterada,
              onQtdSelecaoAlterada: _ctx.qtdSelecaoAlterada,
              onIdentificadoresSelecionados: _ctx.identificadoresSelecionados,
              onClickNovo: _ctx.clickNovo,
              onClickVisualizar: _ctx.clickVisualizar,
              onClickSubMenu: _ctx.clickSubMenu,
              onOrdemCardsAlterada: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ordemCardsAlterada($event)))
            }, {
              menus: _withCtx(() => [
                _renderSlot(_ctx.$slots, "menus", {}, undefined, true)
              ]),
              _: 2
            }, 1032, ["coluna", "onSelecaoCardAlterada", "onColunaCardAlterada", "onQtdSelecaoAlterada", "onIdentificadoresSelecionados", "onClickNovo", "onClickVisualizar", "onClickSubMenu"])
          ]))
        }), 128))
      ])
    ])
  ]))
}