export enum EFiltroPeriodoMovimentoComercial
{
    Hoje = 1,
    Ontem = 2,
    SemanaAtual = 3,
    MesAtual = 4,
    AnoAtual = 5,
    Ultimos15Dias = 6,
    Ultimos30Dias = 7,
    Ultimos60Dias = 8,
    Ultimos90dias = 9,
    Personalizado = 10,
}
