
import { computed, defineComponent } from 'vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';

export default defineComponent({
  name: 'CampoCpfCnpj',
  components: {
  },
  props: {
    valor: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:valor'],
  setup(props, { emit }) {
    const computedValor = computed({
      get: () => props.valor,
      set: (valor: string) => {
        if (valor === undefined || valor === null) {
          emit('update:valor', '');
        } else {
          emit('update:valor', UtilitarioMascara.removerMascara(valor));
        }
      },
    });

    return {
      props,
      computedValor,
      UtilitarioMascara,
    };
  },
});
